import { useAuth0 } from '@auth0/auth0-react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerProps,
  useDisclosure
} from '@chakra-ui/react'
import { CrossIcon, HamburgerMenuIcon, UserIcon } from 'icons'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useState } from 'react'
import SignUpModal, {
  LoginConnectProps,
  SignUpType
} from 'swnz/src/components/SignUpModal'
import breakpoints from 'theme/src/theme/foundations/breakpoints'
import { useMediaQuery } from 'utils/src/helpers'
import { CtaButton } from '../buttons'

const ROUTE_CHANGE_EVENT = 'routeChangeComplete'
const HASH_CHANGE_EVENT = 'hashChangeComplete'
interface NavigationContextInterface {
  isOpen: boolean
  onOpen(): void
  onClose(): void
  profileNav: {
    isOpen: boolean
    onOpen(): void
    onClose(): void
  }
}

const NavigationContext =
  React.createContext<NavigationContextInterface | null>(null)
interface NavigationProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

export function NavigationProvider({
  children
}: NavigationProviderProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const profileNav = useDisclosure()

  return (
    <NavigationContext.Provider value={{ onClose, onOpen, isOpen, profileNav }}>
      {children}
    </NavigationContext.Provider>
  )
}

export const DRAWER_SIZE = Object.freeze({
  SMALL: 'xs',
  FULL_SCREEN: 'full'
})

const CHAKRA_BREAKPOINT_SM = 576

// this "hack" is to allow the click of hamburger or profile nav
// when other navs are open
const openNavStyles = `
  .nav-open .chakra-modal__content-container {
    top: 6rem
  }
`

interface NavWrapperProps {
  children: ReactElement | ReactElement[]
}

const SignInButton = () => {
  const { onClose, isOpen } = useDisclosure()
  const { t } = useTranslation('navigation')
  const [currentLoginState, setCurrentLoginState] = useState<LoginConnectProps>(
    {
      openModal: false
    }
  )

  const handleOpenModal = (loginState: LoginConnectProps) => {
    setCurrentLoginState(loginState)
  }

  const handleCloseModal = (loginState: LoginConnectProps) => {
    onClose()
    setCurrentLoginState(loginState)
  }

  return (
    <>
      <CtaButton
        bg='none'
        border='0'
        onClick={() =>
          handleOpenModal({ loginType: SignUpType.Default, openModal: true })
        }
        modalId='sign-in-modal'
      >
        {`${t('sign_in')}`}
      </CtaButton>
      <SignUpModal
        loginState={currentLoginState}
        isOpen={isOpen}
        onClose={() => {
          handleCloseModal({ openModal: false })
        }}
      />
    </>
  )
}

export const SignInSignOutButton = ({
  handleProfileNavToggle,
  profileNav,
  handleCloseNav,
  size
}) => {
  const { isAuthenticated, isLoading } = useAuth0()
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`)
  const { t } = useTranslation('navigation')

  return (
    <>
      {isAuthenticated && !isLoading ? (
        <>
          <Box>
            <CtaButton
              sx={
                isMobile
                  ? {
                      padding: 0,
                      '> .chakra-button__icon': {
                        margin: 0
                      }
                    }
                  : {}
              }
              colorScheme='white'
              border={0}
              onClick={() => handleProfileNavToggle()}
              bg={
                profileNav.isOpen ? 'rgba(255, 255, 255, 0.1)' : 'transparent'
              }
              rightIcon={
                isMobile ? undefined : (
                  <ChevronDownIcon
                    h={8}
                    w={8}
                    transform={
                      profileNav.isOpen ? 'rotate(180deg)' : 'rotate(0)'
                    }
                  />
                )
              }
              leftIcon={
                <Box boxSize={6} marginRight={isMobile ? 0 : 1}>
                  <UserIcon fill='white' />
                </Box>
              }
              pr={4}
              mr={6}
              aria-label={t('welcome')}
            >
              {isMobile ? ' ' : `${t('welcome')}`}
            </CtaButton>
          </Box>
        </>
      ) : (
        <Box mr={2}>
          <SignInButton />
        </Box>
      )}
    </>
  )
}

export function useHeaderDrawer() {
  const router = useRouter()

  const [size, setSize] = useState<string>(DRAWER_SIZE.SMALL)
  const { onClose, onOpen, isOpen, profileNav } = useNavigation()

  const handleProfileNavToggle = () => {
    document.body.classList.add('nav-open')
    profileNav.isOpen ? profileNav.onClose() : (profileNav.onOpen(), onClose())
  }

  const handleOpenMainNav = () => {
    document.body.classList.add('nav-open')
    onOpen(), profileNav.isOpen && profileNav.onClose()
  }

  const handleCloseNav = (isProfile: boolean) => {
    isProfile ? profileNav.onClose() : onClose()
  }

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= CHAKRA_BREAKPOINT_SM)
          setSize(DRAWER_SIZE.FULL_SCREEN)
        else setSize(DRAWER_SIZE.SMALL)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    // When the router changes we want to close any nav that is open
    const handleRouteChange = () => {
      profileNav.onClose(), onClose()
    }
    //remove added css class when both navs are closed
    !profileNav.isOpen && !isOpen && document.body.classList.remove('nav-open')
    router.events.on(ROUTE_CHANGE_EVENT, handleRouteChange)
    router.events.on(HASH_CHANGE_EVENT, handleRouteChange)

    return () => {
      window.removeEventListener('resize', handleResize),
        router.events.off(ROUTE_CHANGE_EVENT, handleRouteChange),
        router.events.off(HASH_CHANGE_EVENT, handleRouteChange)
    }
  }, [router.events, onClose, profileNav, isOpen])

  return {
    handleProfileNavToggle,
    handleCloseNav,
    handleOpenMainNav,
    profileNav,
    size,
    isOpen
  }
}

export function NavDrawer({
  handleCloseNav,
  isOpen,
  size,
  children,
  placement = 'right'
}: {
  handleCloseNav: (isProfile: boolean) => void
  isOpen: boolean
  size: string
  children: React.ReactNode
  placement?: DrawerProps['placement']
}) {
  return (
    <Drawer
      onClose={() => handleCloseNav(false)}
      isOpen={isOpen}
      size={size}
      placement={placement}
    >
      <DrawerContent
        bg='deepblue.500'
        color='white'
        top='75px !important'
        pb={{ base: '75px', xl: '92px' }}
        maxH='100%'
        height='100%'
        overflowY='scroll'
      >
        <DrawerBody pos='unset' p='24px'>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export function NavButton({
  isOpen,
  handleCloseNav,
  handleOpenMainNav
}: {
  isOpen: boolean
  handleCloseNav: (isProfile: boolean) => void
  handleOpenMainNav: () => void
}) {
  return (
    <>
      {isOpen ? (
        <CtaButton
          tabIndex={1}
          borderRadius='full'
          border='none'
          boxSize={10}
          display='flex'
          justifyContent='center'
          alignItems='center'
          aria-label='close navigation'
          bg='transparent'
          icon={
            <Box w='32px' h='32px'>
              <CrossIcon />
            </Box>
          }
          onClick={() => handleCloseNav(false)}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.16)'
          }}
        >
          <></>
        </CtaButton>
      ) : (
        <CtaButton
          tabIndex={1}
          borderRadius='full'
          border='none'
          boxSize={10}
          display='flex'
          justifyContent='center'
          alignItems='center'
          aria-label='open navigation'
          bg='transparent'
          icon={
            <Box w='32px' h='32px'>
              <HamburgerMenuIcon />
            </Box>
          }
          onClick={() => handleOpenMainNav()}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.16)'
          }}
        >
          <></>
        </CtaButton>
      )}
    </>
  )
}

const NavWrapper = ({ children }: NavWrapperProps) => {
  return (
    <>
      <style>{openNavStyles}</style>
      {children}
    </>
  )
}

function useNavigation() {
  const context = React.useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigation must be defined within a NavigationProvider')
  }
  return context
}

export default NavWrapper
export { useNavigation }
