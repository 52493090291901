import { connectHits, HitsProvided } from 'react-instantsearch-core'
import { FinderResultItem } from 'ui'

export interface SiteSearchHit {
  id: string
  title: string
  url: string
  content: string
  hierarchy: {
    l0: string
    l1: string
    l2: string
    l3: string
    l4: string
    content: string
  }
}

type SiteSearchHits = HitsProvided<SiteSearchHit>

const determineSiteSearchHitTitle = (hit: SiteSearchHit) => {
  if (hit.url === '/' || (!hit.hierarchy.l1 && !hit.hierarchy.l0)) {
    return 'Homepage'
  }

  if (hit.hierarchy.l1) {
    return hit.hierarchy.l1
  }

  return hit.hierarchy.l0
}

function SiteSearchHit({ hit }: { hit: SiteSearchHit }) {
  const title = determineSiteSearchHitTitle(hit)

  return (
    <FinderResultItem
      key={hit.id}
      px={0}
      py={0}
      pb={{ base: 5, lg: 6 }}
      mb={0}
      borderBottomWidth="1px"
      borderBottomColor="deepblue.100"
      borderRadius="none"
    >
      <FinderResultItem.MainContent>
        <FinderResultItem.Title
          title={title}
          link={`${hit.url}`}
          customFontWeight={'500'}
          customMarginTop={{ base: 5, lg: 6 }}
          customFontSize={{ base: '22px', lg: '22px' }}
          customLineHeight={{ base: '32px', lg: '32px' }}
        />
        <FinderResultItem.Description
          intro={hit.content}
          maxW="100%"
          customTitleFontColor={'deepWaterBlue'}
          customTitleFontSize={'16px'}
          customTitleLineHeight={'28px'}
        />
        <FinderResultItem.URL
          label={`${process.env.NEXT_PUBLIC_URL}${hit.url}`}
          url={hit.url}
          title={title}
        />
      </FinderResultItem.MainContent>
    </FinderResultItem>
  )
}

function SiteSearchHits({ hits }: SiteSearchHits) {
  return (
    <>
      {hits.map((hit: SiteSearchHit) => (
        <SiteSearchHit key={hit.id} hit={hit} />
      ))}
    </>
  )
}

export default connectHits<SiteSearchHits, SiteSearchHit>(SiteSearchHits)
