import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
} from '@chakra-ui/react'
import { VerticalThinkNewNZLogo } from 'icons'
import { useRef } from 'react'
import Headroom from 'react-headroom'
import { CONTENT_SERVICE } from 'src/constants'
import breakpoints from 'theme/src/theme/foundations/breakpoints'
import { Link, NavigationProvider, NavWrapper } from 'ui'
import { useMediaQuery, ZINDEX } from 'utils'
import { Nav } from '../Nav'
import SiteSearchButton from '../Search/SiteSearch/SiteSearchButton'
import { useSiteSearchDrawer } from '../Search/SiteSearch/SiteSearchDrawer'
import SiteSearchInput from '../Search/SiteSearch/SiteSearchInput'
import { LocaleMenuDesktop, NavLinks, ProfileMenuDesktop } from '../TopNav'

function Header({ preview, locale }: { preview: boolean; locale: string }) {
  const { isSiteSearchOpen, handleCloseSiteSearch } = useSiteSearchDrawer()
  const portalRef = useRef(null)
  const isMobileOrTablet = useMediaQuery(`(max-width: ${breakpoints.lg})`)

  return (
    <>
      <Box bg="deepblue.500" as="header">
        <Flex
          position="relative"
          zIndex={{ lg: ZINDEX.TOP_NAV }}
          display={{ base: 'none', lg: 'flex' }}
          maxW={{ base: 'container.xl', xxl: 'none' }}
          w="100%"
          m="auto"
          bg="deepblue.500"
          color="white"
          justifyContent="flex-end"
          alignItems="center"
          py={2}
          pl={16}
          pr={16}
          gap={6}
          borderBottom="1px solid rgba(255,255,255,0.24)"
        >
          <NavLinks preview={preview} locale={locale} codeId={CONTENT_SERVICE.SECONDARY_TOP_NAV} />
          <LocaleMenuDesktop />
          <ProfileMenuDesktop preview={preview} locale={locale} />
        </Flex>
        <Headroom style={{ zIndex: ZINDEX.HEADER }} pinStart={isMobileOrTablet ? 0 : 49}>
          <Flex
            position="relative"
            zIndex={{ base: ZINDEX.SITE_SEARCH, lg: 'unset' }}
            bg="deepblue.500"
            maxW={{ base: 'container.xl', xxl: 'none' }}
            m="auto"
            color="white"
            align="center"
            py={{ base: 4, lg: 0 }}
            pl={{ base: 6, lg: 16 }}
            pr={{ base: 4, lg: 16 }}
          >
            <Link
              tabIndex={1}
              dataTestid="header-logo-link"
              href="/"
              position="relative"
              zIndex={{ lg: ZINDEX.SITE_SEARCH }}
            >
              <Box
                h={{ base: '44px', lg: '72px' }}
                w={{ base: '112px', lg: '154px' }}
                color="white"
                sx={{
                  '>svg': {
                    margin: 'auto 0',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                  },
                }}
              >
                <VerticalThinkNewNZLogo />
              </Box>
            </Link>
            <Spacer />
            <NavWrapper>
              <NavigationProvider>
                <Nav preview={preview} locale={locale} codeId={CONTENT_SERVICE.HEADER_CODE_ID} />
                <Spacer display={{ base: 'none', lg: 'block' }} />
                <Box
                  position="relative"
                  zIndex={{ lg: ZINDEX.SITE_SEARCH }}
                  display={{ base: 'none', lg: 'block' }}
                >
                  <SiteSearchButton />
                </Box>
              </NavigationProvider>
            </NavWrapper>
          </Flex>
          <Flex position="relative" ref={portalRef} />
        </Headroom>
      </Box>
      {/* Site Search Drawer */}
      <Drawer
        portalProps={{ containerRef: portalRef }}
        placement="top"
        onClose={handleCloseSiteSearch}
        isOpen={isSiteSearchOpen}
        size="full"
      >
        <DrawerOverlay backgroundColor="rgba(7, 25, 55, 0.8)" top={{ base: '76px', lg: 0 }} />
        <DrawerContent
          backgroundColor="deepblue.500"
          height="fit-content"
          paddingTop={{ base: '76px', lg: 0 }}
        >
          <Box padding={{ base: '24px', lg: '22px 0' }}>
            <Box
              maxW="container.xl"
              w="100%"
              m="0 auto"
              color="white"
              display="flex"
              flexDir={{ base: 'column-reverse', lg: 'row' }}
            >
              <DrawerBody
                sx={{
                  'form[role="search"] > .chakra-stack': {
                    marginBottom: 0,
                  },
                  '.chakra-stack > .chakra-input__group': {
                    marginTop: 0,
                  },
                }}
                maxW={{ lg: '600px' }}
                w="100%"
                mx="auto"
                flex={1}
                padding={0}
                overflow="visible"
              >
                <SiteSearchInput />
              </DrawerBody>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Header
