export default {
  global: {
    html: {
      fontSynthesis: 'none !important'
    },
    'html body': {
      wordBreak: 'keep-all'
    },
    'html:lang(vi) *': {
      fontFamily: 'NotoSans Regular, Helvetica, Arial, sans-serif',
      letterSpacing: '0 !important'
    },
    'html[lang=ar] *': {
      fontFamily: 'system-ui, sans-serif !important',
      fontWeight: 'normal !important',
      letterSpacing: '0 !important'
    },
    'html:not([lang=en]) h2, h6': {
      fontWeight: '700',
      fontFamily: 'NotoSans Regular, Helvetica, Arial, sans-serif',
      letterSpacing: '0 !important'
    },
    'html:lang(th) body': {
      wordBreak: 'normal'
    },
    'html:lang(ja) body': {
      wordBreak: 'normal'
    },
    'html:lang(vi) body': {
      wordBreak: 'normal'
    },
    'html:lang(cn) body': {
      wordBreak: 'normal'
    },
    body: {
      position: 'relative',
      bg: 'white',
      color: 'deepWaterBlue',
      fontFamily: 'enz400',
      overflowX: 'hidden',
      WebkitFontSmoothing: 'auto'
    },
    hr: {
      marginTop: 6,
      marginBottom: 6
    }
  }
}
