import { AutocompleteApi } from '@algolia/autocomplete-core'
import { Box, Center, Divider, Flex, HStack, Input } from '@chakra-ui/react'
import { forwardRef, ReactElement, useImperativeHandle, useRef } from 'react'
import { SEARCH_BAR } from 'utils'
import SearchInputIcon from '../SearchInputIcon/SearchInputIcon'
import SearchTitleCta from '../SearchTitleCta'
import { SearchTitleCtaProps } from '../SearchTitleCta/SearchTitleCta'
import { AutocompleteResults } from './SearchAutocomplete'
interface SearchAutocompleteInput {
  title: string
  autocomplete: AutocompleteApi<AutocompleteResults>
  showLabel?: boolean
  searchTitleCta?: SearchTitleCtaProps
  size?: 'sm' | 'md' | 'lg'
  iconPlacement?: 'start' | 'end'
}

export interface AutocompleteInputRef {
  form: HTMLFormElement | null
  input: HTMLInputElement | null
}

const SearchAutocompleteInput = forwardRef<
  AutocompleteInputRef,
  SearchAutocompleteInput
>(
  (
    {
      title,
      autocomplete,
      showLabel = false,
      size = 'md',
      searchTitleCta,
      iconPlacement = 'end'
    },
    ref
  ): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      get form() {
        return formRef.current
      },
      get input() {
        return inputRef.current
      }
    }))
    const searchButtonSize = size === 'sm' ? '24px' : '60px'
    const { getFormProps, getLabelProps, getInputProps } = autocomplete

    return (
      //@ts-ignore
      <form ref={formRef} {...getFormProps({ inputElement: inputRef.current })}>
        <HStack
          spacing={4}
          mb={{ base: 2, lg: 4 }}
          display={{ base: 'none', md: showLabel ? 'flex' : 'none' }}
        >
          <Box fontFamily='enz700' fontSize='20px' {...getLabelProps({})}>
            {title}
          </Box>
          {searchTitleCta && (
            <>
              <Center height={6} display={{ base: 'none', lg: 'unset' }}>
                <Divider
                  orientation='vertical'
                  color='deepblue.200'
                  opacity={1}
                  m='0'
                />
              </Center>

              <SearchTitleCta
                {...searchTitleCta}
                display={{ base: 'none', md: 'inline-block' }}
              />
            </>
          )}
        </HStack>

        <Flex alignItems='center'>
          {iconPlacement === 'start' ? (
            <SearchInputIcon
              sx={{
                right: 'auto',
                left: 4,
                // actually rtl
                'html[lang=ar] &': {
                  right: 'auto',
                  left: 4
                }
              }}
              searchButtonSize={searchButtonSize}
              boxSize={size === 'sm' ? searchButtonSize : { base: 8, lg: 9 }}
            />
          ) : null}
          {
            //@ts-ignore
            <Input
              title={title}
              color={'deepWaterBlue'}
              ref={inputRef}
              paddingInlineStart={iconPlacement === 'start' ? '40px' : 4}
              paddingInlineEnd={iconPlacement === 'end' ? searchButtonSize : 4}
              h={{
                base: size === 'sm' ? '44px' : '60px',
                lg: size === 'sm' ? '44px' : size === 'lg' ? '80px' : '60px'
              }}
              lineHeight={{
                lg: size === 'sm' ? '44px' : size === 'lg' ? '80px' : '60px'
              }}
              fontSize={{ lg: size === 'lg' ? '22px' : 'md' }}
              fontFamily='enz400'
              sx={{
                ...SEARCH_BAR.HOVER
              }}
              {...getInputProps({ inputElement: null })}
            />
          }
          {iconPlacement === 'end' ? (
            <SearchInputIcon
              sx={{
                right: 0,
                left: 'auto',
                // actually rtl
                'html[lang=ar] &': {
                  right: 0,
                  left: 'auto'
                }
              }}
              searchButtonSize={searchButtonSize}
              boxSize={size === 'sm' ? searchButtonSize : { base: 8, lg: 9 }}
            />
          ) : null}
        </Flex>
      </form>
    )
  }
)

SearchAutocompleteInput.displayName = 'SearchAutocompleteInput'

export default SearchAutocompleteInput
