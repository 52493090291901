import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react'
import { ChevronCircleLarge } from 'icons'
import { useRouter } from 'next/router'
import { Fragment } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, NavLink } from 'ui'
import findIndexWithActiveLink from './helpers/findIndexWithActiveLink'

const NavAccordion = ({ links }: { links: NavLink[] }) => {
  const router = useRouter()
  const currentPath = router.asPath

  const indexWithActiveLink = findIndexWithActiveLink(
    links.filter((link) => link.children && link.children.length > 0),
    currentPath
  )

  return (
    <Accordion allowMultiple defaultIndex={indexWithActiveLink}>
      {links.map((link) => {
        if (!link) return null

        if (link.children && link.children.length > 0) {
          return (
            <AccordionItem key={link.id} border="none">
              {({ isExpanded }) => (
                <>
                  <Flex
                    pt={2}
                    pb={link.slug === currentPath ? 3 : 2}
                    flexDir="row"
                    gap={4}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Link
                      tabIndex={1}
                      href={link.slug}
                      isExternal={link.isExternalLink}
                      display="block"
                      fontFamily="enz700"
                      fontSize="16px"
                      lineHeight="28px"
                      textDecoration="none"
                      color="white"
                      _hover={{
                        color: 'navHover',
                      }}
                      w="100%"
                      onClick={() => {
                        createUtagLinkClickEvent({
                          linkLabel: link.title,
                          linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                        })
                      }}
                    >
                      <Box
                        as="span"
                        borderBottom={link.slug === currentPath ? '2px solid' : 'none'}
                        borderColor="surfaceBlue"
                        pb={link.slug === currentPath ? 1 : 0}
                      >
                        {link.title}
                      </Box>
                    </Link>
                    <AccordionButton
                      tabIndex={1}
                      w="fit-content"
                      p={0}
                      m={0}
                      color="white"
                      _hover={{
                        color: 'navHover',
                        '& path': {
                          fill: 'navHover',
                        },
                        '& circle': {
                          stroke: 'navHover',
                          fill: 'deepblue.500',
                        },
                      }}
                      sx={{
                        '& path': {
                          fill: isExpanded ? 'deepblue.500!' : '',
                        },
                        '& circle': {
                          stroke: isExpanded ? 'surfaceBlue!' : '',
                          fill: isExpanded ? 'surfaceBlue!' : '',
                        },
                      }}
                    >
                      <Box
                        h="32px"
                        w="32px"
                        transition="all"
                        transitionDuration="200ms"
                        transform={`rotate(${isExpanded ? '-' : ''}90deg)`}
                        sx={{
                          path: {
                            fill: isExpanded ? 'deepblue.500' : 'white',
                          },
                          circle: {
                            stroke: isExpanded ? 'surfaceBlue' : 'white',
                            fill: isExpanded ? 'surfaceBlue' : 'deepblue.500',
                          },
                        }}
                      >
                        <ChevronCircleLarge />
                      </Box>
                    </AccordionButton>
                  </Flex>
                  <AccordionPanel m={0} px={0} pt={link.slug === currentPath ? 0 : 1} pb={4}>
                    {link.children && link.children.length > 0
                      ? link.children.map((child) => {
                          if (!child) return null

                          return (
                            <Fragment key={child.id}>
                              <Link
                                onClick={() => {
                                  createUtagLinkClickEvent({
                                    linkLabel: child.title,
                                    linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                                  })
                                }}
                                tabIndex={1}
                                href={child.slug}
                                isExternal={child.isExternalLink}
                                display="block"
                                w="100%"
                                py={1}
                                px={4}
                                fontFamily="enz400"
                                fontSize="16px"
                                lineHeight="28px"
                                textDecoration="none"
                                color="white"
                                _hover={{
                                  color: 'navHover',
                                }}
                              >
                                <Box
                                  as="span"
                                  borderBottom={child.slug === currentPath ? '2px solid' : 'none'}
                                  borderColor="surfaceBlue"
                                  pb={child.slug === currentPath ? 1 : 0}
                                >
                                  {child.title}
                                </Box>
                              </Link>
                              {child.children && child.children.length > 0 ? (
                                <Flex flexDir="column">
                                  {child.children.map((grandchild) => {
                                    if (!grandchild) return null

                                    return (
                                      <Link
                                        onClick={() => {
                                          createUtagLinkClickEvent({
                                            linkLabel: grandchild.title,
                                            linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                                          })
                                        }}
                                        tabIndex={1}
                                        key={grandchild.id}
                                        href={grandchild.slug}
                                        isExternal={grandchild.isExternalLink}
                                        display="block"
                                        w="100%"
                                        px={8}
                                        py={1}
                                        fontFamily="enz400"
                                        fontSize="16px"
                                        lineHeight="28px"
                                        textDecoration="none"
                                        color="white"
                                        _hover={{
                                          color: 'navHover',
                                        }}
                                      >
                                        <Box
                                          as="span"
                                          borderBottom={
                                            grandchild.slug === currentPath ? '2px solid' : 'none'
                                          }
                                          borderColor="surfaceBlue"
                                          pb={grandchild.slug === currentPath ? 1 : 0}
                                        >
                                          — {grandchild.title}
                                        </Box>
                                      </Link>
                                    )
                                  })}
                                </Flex>
                              ) : null}
                            </Fragment>
                          )
                        })
                      : null}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          )
        } else
          return (
            <Link
              onClick={() => {
                createUtagLinkClickEvent({
                  linkLabel: link.title,
                  linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                })
              }}
              tabIndex={1}
              key={link.id}
              display="block"
              w="100%"
              py={2}
              fontFamily="enz700"
              fontSize="16px"
              lineHeight="28px"
              textDecoration="none"
              color="white"
              _hover={{
                color: 'navHover',
              }}
              href={link.slug}
              isExternal={link.isExternalLink}
            >
              <Box
                as="span"
                pb={link.slug === currentPath ? 1 : 0}
                borderBottom={link.slug === currentPath ? '2px solid' : 'none'}
                borderColor="surfaceBlue"
              >
                {link.title}
              </Box>
            </Link>
          )
      })}
    </Accordion>
  )
}

export default NavAccordion
