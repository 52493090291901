import { Flex, Text } from '@chakra-ui/react'

const NavIntroduction = ({
  title,
  description,
}: {
  title?: string | null
  description?: string | null
}) => {
  if (!title && !description) return null

  return (
    <Flex flexDir="column" gap={4}>
      {title && (
        <Text
          fontFamily="enz700"
          fontSize={{ base: '22px', lg: '26px' }}
          lineHeight={{ base: '28px', lg: '28px' }}
          mb={0}
        >
          {title}
        </Text>
      )}
      {description && description.length > 0 ? (
        <Text opacity={0.75} fontFamily="enz400" fontSize="16px" lineHeight="28px" mb={0}>
          {description}
        </Text>
      ) : null}
    </Flex>
  )
}

export default NavIntroduction
