const IMAGE_SIZE_CONFIG = {
  FEATURED_HERO: { width: 2560, height: 1440 },
  FULLBLEED_HERO: { width: 2560, height: 1440 },
  REDUCDED_HERO: { width: 1100, height: 800 },
  MEDIA_IMAGE: {
    IMAGE: { width: 1000, height: 700 },
    ZOOM_IMAGE: { width: 2840, height: 1598 }
  },
  MEDIA_VIDEO: { width: 1000, height: 700 },
  CARD_CAROUSEL_GALLERY_CARD: { width: 600, height: 600 },
  ALTERNATING_CONTENT: { width: 800, height: 600 },
  GALLERY_CAROUSEL: { width: 1100, height: 600 },
  HOMEPAGE_HERO_TABS: {
    TAB_BACKGROUND_IMAGE: { width: 350, height: 100 },
    TAB_THUMBNAIL_IMAGE: { width: 100, height: 100 },
    BACKGROUND_IMAGE: { width: 600, height: 300 },
    FOREGROUND_IMAGE: { width: 1100, height: 700 },
    STORY_IMAGE_ONE: { width: 430, height: 340 },
    STORY_IMAGE_TWO: { width: 700, height: 330 }
  },
  HOMEPAGE_CARD: { width: 200, height: 200 },
  REGION_CARD: { width: 800, height: 600 },
  TILE: { width: 800, height: 600 },
  NAV_FEATURED_STORY: { width: 120, height: 120 }
}

export default IMAGE_SIZE_CONFIG
