
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { captureException, withScope } from '@sentry/nextjs';
import { addApolloState, initializeApollo, Seo_Swnz_SeoFragment, useGetPageCollectionBySlugQuery, } from 'content-service';
import { connectorElementQueries, footerQuery, pageContentQuery, standardPageHeroQuery, swnzHeaderQuery, swnzTopNavQuery, } from 'content-service/src/helpers';
import { GetStaticProps } from 'next';
import { CONTENT_SERVICE } from 'src/constants';
import StandardPage from 'src/templates/StandardPage';
import { useTrackPageViews } from 'tracking';
import { Error as ErrorComponent, PageSeo } from 'ui';
import { getQueryLocale } from 'utils';
import { buildPageQueryInput } from 'utils/src/helpers/slug';
import { SITE_CONFIG } from '../constants';
interface HomeProps {
    slug: string;
    preview: boolean;
    locale: string;
    pageSeo: Seo_Swnz_SeoFragment;
    pageTheme?: string;
}
const Home = ({ preview, locale, pageSeo, slug, pageTheme }: HomeProps) => {
    const { data, error } = useGetPageCollectionBySlugQuery({
        variables: {
            ...buildPageQueryInput(slug),
            preview,
            locale
        }
    });
    const [page] = data?.swnz_pageCollection?.items ?? [];
    const tags = page?.content?.__typename === 'SWNZ_PageStandard' ? page?.content?.tags : undefined;
    useTrackPageViews({ pageName: pageSeo?.title ?? 'Homepage', pageViewData: tags });
    if (error || !page) {
        withScope(function (scope) {
            scope.setExtras({ path: slug, error, page });
            captureException(new Error('Slug Page Error slug /'));
        });
        return <ErrorComponent preview={preview} title={slug} statusCode={404}/>;
    }
    return (<>
      <PageSeo pageSeo={pageSeo}/>
      {page?.content?.__typename === 'SWNZ_PageStandard' && (<StandardPage codeId={page.codeId} content={page.content} pageSeo={pageSeo} pageTheme={pageTheme}/>)}
    </>);
};
export default Home;
const getStaticProps: GetStaticProps = async ({ locale, preview = false, defaultLocale, }) => {
    const slug = SITE_CONFIG.SITE_ROOT_PATH;
    const queryLocale = getQueryLocale(locale, defaultLocale);
    const apolloClient = initializeApollo({ preview: preview });
    let showLcf = true;
    try {
        const pageContentQueryResponse = await pageContentQuery({
            apolloClient,
            slug,
            preview,
            locale: queryLocale
        });
        const { pageSeo, pageTheme, elementData } = pageContentQueryResponse;
        // The page doesn't exist in the CMS
        if (elementData === undefined) {
            return { notFound: true };
        }
        if (!pageSeo) {
            console.error('🚨 Error in pageSeo response for home page');
        }
        const [...rest] = await Promise.allSettled([
            swnzHeaderQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.HEADER_CODE_ID,
                preview,
                locale: queryLocale
            }),
            swnzTopNavQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.SECONDARY_TOP_NAV,
                preview,
                locale: queryLocale
            }),
            swnzTopNavQuery({
                apolloClient,
                codeId: CONTENT_SERVICE.SECONDARY_TOP_NAV_LOGGED_IN,
                preview,
                locale: queryLocale
            }),
            standardPageHeroQuery({
                apolloClient,
                slug,
                preview,
                locale: queryLocale
            }),
            footerQuery({ apolloClient, preview, locale: queryLocale }),
        ]);
        rest.filter((queryResponse) => {
            if (queryResponse.status !== 'fulfilled') {
                console.error('🚨 Error in query response for home page');
            }
        });
        if (pageContentQueryResponse?.showLcf === false) {
            showLcf = false;
        }
        if (elementData?.length) {
            await connectorElementQueries(apolloClient, elementData, queryLocale, preview);
        }
        else {
            console.error('🚨 Error in pageContentQueryResponse response for home page');
            return { notFound: true };
        }
        return addApolloState(apolloClient, {
            props: { slug, preview, locale: queryLocale, pageSeo, pageTheme, showLcf }
        });
    }
    catch (error) {
        console.error('🚨 Error pregenerating home page');
        return { notFound: true };
    }
};

    async function __Next_Translate__getStaticProps__194a70c8abe__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194a70c8abe__ as getStaticProps }
  