import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { EnquiryIcon, FavouritedIcon, LogoutIcon, RecommendationsIcon, UserIcon } from 'icons'
import { ROUTES } from 'src/constants'
import { useTranslation } from 'src/hooks/useTranslation'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, removeLoginDataCookie } from 'ui'

const ProfileNavLinks = ({ callback }: { callback?: () => void }) => {
  const { logout } = useAuth0()
  const { t } = useTranslation('navigation')

  const handleLogoutClick = () => {
    removeLoginDataCookie()
    logout({
      logoutParams: {
        returnTo: process.env.NEXT_PUBLIC_URL ?? '',
      },
    })
  }

  const profileLinks = [
    {
      id: 'recommendations',
      slug: ROUTES.RECOMMENDATIONS,
      title: t('recommendations') ?? 'Recommendations',
      icon: RecommendationsIcon,
    },
    {
      id: 'favourites',
      slug: ROUTES.FAVOURITES,
      title: t('favourites') ?? 'Favourites',
      icon: FavouritedIcon,
    },
    {
      id: 'enquired',
      slug: ROUTES.ENQUIRIES,
      title: t('enquiries') ?? 'Enquiries',
      icon: EnquiryIcon,
    },
    {
      id: 'profile',
      slug: ROUTES.PROFILE,
      title: t('your_profile') ?? 'Your profile',
      icon: UserIcon,
    },
  ]

  return (
    <Flex as="ul" flexDir="column" gap={4}>
      {profileLinks.map((link) => (
        <Box as="li" listStyleType="none" key={link.id}>
          <Link
            tabIndex={1}
            color="white"
            _hover={{
              color: 'navHover',
            }}
            textDecor="none"
            fontSize="14px"
            py="6px"
            fontFamily="enz500"
            lineHeight="20px"
            href={link.slug}
            onClick={() => {
              createUtagLinkClickEvent({
                linkLabel: link.title,
                linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
              })
              callback?.()
            }}
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon as={link.icon} width="20px" height="20px" opacity={0.75} />
            <Text m={0}>{link.title}</Text>
          </Link>
        </Box>
      ))}
      <Box as="li" listStyleType="none">
        <Box as="hr" background="white" opacity={0.24} m={0} />
      </Box>
      <Box as="li" listStyleType="none">
        <Link
          tabIndex={1}
          color="white"
          _hover={{
            color: 'navHover',
          }}
          textDecor="none"
          fontSize="14px"
          py="6px"
          fontFamily="enz500"
          lineHeight="20px"
          href="/dashboard#logout"
          onClick={() => {
            createUtagLinkClickEvent({
              linkLabel: t('sign_out') ?? 'Sign out',
              linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
            })
            handleLogoutClick()
            callback?.()
          }}
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Icon as={LogoutIcon} width="20px" height="20px" opacity={0.75} />
          <Text m={0}>{t('sign_out')}</Text>
        </Link>
      </Box>
    </Flex>
  )
}

export default ProfileNavLinks
