import { Box, Container, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Tag_Swnz_TagFragment } from 'content-service'
import useTranslation from 'next-translate/useTranslation'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { createUtagFilterUpdateEvent } from 'tracking'
import { isPresent } from 'ts-is-present'
import { H2, Headline } from 'ui'
import { colourMap, useMediaQuery } from 'utils/src/helpers'
import CardCarouselCard from './CardCarouselCard'
import CardCarouselHeading from './CardCarouselHeading'
import CardCarouselNavigationButtons from './CardCarouselNavigationButtons'
import CardCarouselTagFilters from './CardCarouselTagFilters'
import {
  setCenterSlidePosition,
  updateCardsBasedOnFilteredValue
} from './helpers'
import { TAG_FILTERS } from './helpers/constants'

type Card = {
  name?: string
  areaOfStudy?: string
  location?: string
  image: ReactElement | null
  videoUrl?: string
  tags?: (Tag_Swnz_TagFragment | null)[]
  modalId?: string | undefined
  title?: string | undefined
  modalHasHeroVideo?: boolean
}

enum NavigationType {
  Next = 'next',
  Previous = 'previous'
}

enum CarouselType {
  StatsImageCarousel = 'statsimagecarousel',
  Story = 'story',
  StoryCardContainer = 'storycardcontainer'
}

interface CardCarouselGalleryProps {
  carouselType?: CarouselType
  title?: string
  subHeading?: string
  backgroundColor: string
  pseudoColor: string
  textColor: string
  cards: (Card | null)[]
}

interface CarouselState {
  numberOfSlides: number
  currentTag: Tag_Swnz_TagFragment
  cards: (Card | null)[]
  currentSlide: number
}

const CAROUSEL_INDEX_CONSTANTS = Object.freeze({
  DESKTOP: {
    START_SLIDE: 1,
    FINAL_SLIDE: 2,
    LOCKED_SLIDE: 2
  },
  MOBILE: {
    START_SLIDE: 0,
    FINAL_SLIDE: 1
  }
})

const CardCarouselGallery = ({
  carouselType,
  title,
  subHeading,
  backgroundColor,
  pseudoColor, // sets bg colour of the ":after" pseudo element on CardCarouselCard and CardCarouselTagFilters
  textColor,
  cards
}: CardCarouselGalleryProps) => {
  const isLargerThan600 = useMediaQuery('(min-width: 600px)')
  const isLargerThan960 = useMediaQuery('(min-width: 960px)')

  const [carouselState, setCarouselState] = useState<CarouselState>({
    numberOfSlides: cards.length,
    currentSlide: CAROUSEL_INDEX_CONSTANTS.MOBILE.START_SLIDE,
    currentTag: TAG_FILTERS.allAreas as Tag_Swnz_TagFragment,
    cards: cards
  })

  const tags = Array.from(
    new Set([...cards.flatMap((card) => card?.tags).filter((item) => !!item)])
  )

  const isStatsImageCarousel = carouselType === CarouselType.StatsImageCarousel
  const isStoryCarousel =
    (tags && tags.length > 0) || carouselType === CarouselType.Story
  const isStoryCardContainer =
    (!isStoryCarousel && !isStatsImageCarousel) ||
    carouselType === CarouselType.StoryCardContainer

  useEffect(() => {
    if (isLargerThan960)
      setCarouselState({
        ...carouselState,
        currentSlide: CAROUSEL_INDEX_CONSTANTS.DESKTOP.START_SLIDE
      })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargerThan960])

  const updateCurrentSlide = (index: number): void => {
    if (carouselState.currentSlide !== index) {
      setCarouselState({
        ...carouselState,
        currentSlide: index
      })
    }
  }

  const handleNavigationNextClick = () => {
    const { numberOfSlides, currentSlide } = carouselState

    const maxSlide: number = isLargerThan960
      ? CAROUSEL_INDEX_CONSTANTS.DESKTOP.FINAL_SLIDE
      : CAROUSEL_INDEX_CONSTANTS.MOBILE.FINAL_SLIDE

    const shouldSkipFirstIndexedCard =
      isLargerThan960 &&
      currentSlide === CAROUSEL_INDEX_CONSTANTS.DESKTOP.START_SLIDE

    if (
      numberOfSlides - maxSlide === currentSlide ||
      numberOfSlides === currentSlide
    )
      return
    else if (shouldSkipFirstIndexedCard) {
      updateCurrentSlide(CAROUSEL_INDEX_CONSTANTS.DESKTOP.LOCKED_SLIDE)
      return
    } else updateCurrentSlide(currentSlide + 1)
  }

  const handleNavigationPreviousClick = () => {
    const { currentSlide } = carouselState

    const minSlide: number = isLargerThan960
      ? CAROUSEL_INDEX_CONSTANTS.DESKTOP.START_SLIDE
      : CAROUSEL_INDEX_CONSTANTS.MOBILE.START_SLIDE

    const shouldSkipFirstIndexedCard =
      isLargerThan960 &&
      currentSlide === CAROUSEL_INDEX_CONSTANTS.DESKTOP.LOCKED_SLIDE

    if (shouldSkipFirstIndexedCard) {
      updateCurrentSlide(CAROUSEL_INDEX_CONSTANTS.DESKTOP.START_SLIDE)
    } else if (currentSlide === minSlide) return
    else updateCurrentSlide(currentSlide - 1)
  }

  const navigationClickHandler = (navigationType: string): number | void => {
    switch (navigationType) {
      case NavigationType.Next:
        return handleNavigationNextClick()
      case NavigationType.Previous:
        return handleNavigationPreviousClick()
      default:
        return
    }
  }

  const tagFilterClickHandler = (tag: Tag_Swnz_TagFragment): void => {
    createUtagFilterUpdateEvent({
      storyFilter: tag.label ?? tag.key ?? ''
    })

    setCarouselState({
      ...carouselState,
      ...{ currentTag: tag }
    })
  }

  useEffect(() => {
    const updatedState = updateCardsBasedOnFilteredValue({
      currentTag: carouselState.currentTag,
      cards,
      isLargerThan600
    })

    setCarouselState({
      ...carouselState,
      ...updatedState
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselState.currentTag])

  const colorVar: string =
    isStoryCarousel || isStoryCardContainer
      ? `--chakra-colors-${colourMap(pseudoColor).split('.').join('-')}`
      : ''
  const { t } = useTranslation('homepage')
  const content = t('carousel_story_number')

  const numberOfStoriesText = content.replace(
    'XXX',
    carouselState.numberOfSlides.toString()
  )

  const carouselOverrideCss = {
    'html[lang=ar] &': {
      //actually left to right
      direction: 'rtl'
    },
    '.slider': {
      paddingTop: `${isLargerThan960 ? 0 : 6}`,
      paddingRight: { base: 0.5, md: 1, lg: 0 }
    },

    'ul > li': {
      '&:first-of-type': {
        marginLeft: isLargerThan960 ? 0 : 4
      },
      '&:nth-of-type(even)': {
        mt: { md: 12, lg: 20 }
      }
    }
  }

  return (
    <Box
      bg={colourMap(backgroundColor)}
      {...(isStoryCarousel || isStoryCardContainer || isStatsImageCarousel
        ? { sx: { py: { base: '60px', lg: '120px' } } }
        : {})}
      position='relative'
      overflow='hidden'
    >
      {isStatsImageCarousel && title ? (
        <VStack marginBottom={6} marginX={4} textAlign='center'>
          <Headline as='h2' color='white'>
            {title}
          </Headline>
        </VStack>
      ) : null}
      {isStoryCarousel ? (
        <Fragment>
          {(title || subHeading) && (
            <CardCarouselHeading title={title} subHeading={subHeading} />
          )}
          <CardCarouselTagFilters
            colorVar={colorVar}
            tags={tags as Tag_Swnz_TagFragment[]}
            onClickHandler={tagFilterClickHandler}
          />
        </Fragment>
      ) : null}
      {isStoryCardContainer && (
        <Container>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent='space-between'
            align={{ lg: 'center' }}
            mb={{ lg: 20 }}
          >
            <H2
              color={textColor}
              mb={{ base: '1rem', lg: 0 }}
              maxW={{ base: '100%', lg: '464px' }}
            >
              {title}
            </H2>
            <Box alignSelf='flex-end'>
              <CardCarouselNavigationButtons
                numberOfSlides={carouselState.numberOfSlides}
                currentSlide={carouselState.currentSlide}
                isMobile={!isLargerThan600}
                onClickHandler={navigationClickHandler}
                color={textColor}
              />
            </Box>
          </Flex>
        </Container>
      )}
      {!isStoryCardContainer && (
        <HStack
          paddingBottom={{ base: 5, lg: 20 }}
          maxW='container.xl'
          paddingX={{ base: 6, lg: 14 }}
          margin='auto'
          justifyContent={isStoryCarousel ? 'space-between' : 'flex-end'}
          alignItems='center'
        >
          {isStoryCarousel && (
            <Text color={textColor} marginBottom='0'>
              {numberOfStoriesText}
            </Text>
          )}
          <CardCarouselNavigationButtons
            numberOfSlides={carouselState.numberOfSlides}
            currentSlide={carouselState.currentSlide}
            isMobile={!isLargerThan600}
            onClickHandler={navigationClickHandler}
            color={textColor}
          />
        </HStack>
      )}
      <Box sx={carouselOverrideCss}>
        <Carousel
          centerMode={true}
          autoPlay={false}
          infiniteLoop={false}
          swipeable={true}
          emulateTouch={true}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={carouselState.currentSlide}
          centerSlidePercentage={setCenterSlidePosition(
            isLargerThan960,
            isLargerThan600
          )}
          onChange={(curIndex) => {
            setCarouselState({
              ...carouselState,
              currentSlide: curIndex
            })
          }}
        >
          {carouselState.cards.filter(isPresent).map((card, index) => {
            if (isStoryCarousel || isStoryCardContainer) {
              return (
                <CardCarouselCard
                  key={`${card.name}-${index}`}
                  image={card.image}
                  name={card.name}
                  areaOfStudy={card.areaOfStudy}
                  location={card.location}
                  index={index}
                  modalId={card.modalId}
                  isMobile={!isLargerThan960}
                  isStoryCard={true}
                  color={colorVar}
                  modalHasHeroVideo={card?.modalHasHeroVideo}
                />
              )
            }

            return (
              <CardCarouselCard
                key={`${card.name}-${index}`}
                image={card.image}
                index={index}
                isMobile={!isLargerThan960}
                isStoryCard={false}
                modalHasHeroVideo={card?.modalHasHeroVideo}
                color={colorVar}
              />
            )
          })}
        </Carousel>
      </Box>
    </Box>
  )
}

export default CardCarouselGallery
export { CarouselType, NavigationType }
export type { Card }
