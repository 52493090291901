import { useAuth0 } from '@auth0/auth0-react'
import { Box, BoxProps, Divider, Drawer, DrawerBody, DrawerContent, Flex } from '@chakra-ui/react'
import { Swnz_NavigationSection } from 'content-service'
import { ChevronCircleLarge } from 'icons'
import { useRouter } from 'next/router'
import { Fragment, useState } from 'react'
import { CONTENT_SERVICE } from 'src/constants'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { useNavigation } from 'ui'
import {
  DRAWER_SIZE,
  NavButton,
  NavDrawer,
  useHeaderDrawer,
} from 'ui/src/components/Nav/NavWrapper'
import { createLinks } from 'utils/src/helpers/nav'
import SiteSearchButton from '../Search/SiteSearch/SiteSearchButton'
import { useSiteSearchDrawer } from '../Search/SiteSearch/SiteSearchDrawer'
import { LocaleMenuMobile, NavLinks, ProfileMenuMobile } from '../TopNav'
import BackButton from './BackButton'
import NavAccordion from './NavAccordion'
import NavCtaBanner from './NavCtaBanner'
import NavFeaturedStories from './NavFeaturedStories'
import NavIntroduction from './NavIntroduction'
import NavQuickLinks from './NavQuickLinks'
import findIndexWithActiveLink from './helpers/findIndexWithActiveLink'

const MobileNav = ({
  links,
  preview,
  locale,
  ...props
}: BoxProps & {
  links: Swnz_NavigationSection[]
  preview: boolean
  locale: string
}) => {
  const { handleCloseNav, isOpen, handleOpenMainNav } = useHeaderDrawer()
  const { isSiteSearchOpen, handleCloseSiteSearch } = useSiteSearchDrawer()
  const { onClose } = useNavigation()
  const [drawerId, setDrawerId] = useState<string | null>(null)
  const { isAuthenticated } = useAuth0()
  const router = useRouter()
  const currentPath = router.asPath
  const drawerItem = links.find((item) => item.sys.id === drawerId)

  const closeNestedDrawer = () => {
    setDrawerId(null)
  }

  const handleOpenNav = () => {
    if (isSiteSearchOpen) {
      handleCloseSiteSearch()
    }
    handleOpenMainNav()
  }

  const drawerPlacement = locale === 'ar' ? 'left' : 'right'

  return (
    <Box {...props}>
      <Flex>
        <SiteSearchButton />
        <NavButton
          isOpen={isOpen}
          handleCloseNav={handleCloseNav}
          handleOpenMainNav={handleOpenNav}
        />
      </Flex>
      <NavDrawer
        handleCloseNav={handleCloseNav}
        isOpen={isOpen}
        size={DRAWER_SIZE.FULL_SCREEN}
        placement={drawerPlacement}
      >
        <Flex as="nav" flexDir="column" gap={10}>
          <Flex as="ul" flexDir="column">
            {links.map((item) => {
              if (!item) return null

              const itemLinks = createLinks({
                linkItems: { items: item?.navigationElementsCollection?.items ?? [] },
              })

              const itemHasActiveLink =
                findIndexWithActiveLink(itemLinks, currentPath, { checkTopLevelLink: true }) !==
                undefined

              return (
                <Fragment key={item.sys.id}>
                  <Box as="li" style={{ listStyleType: 'none' }}>
                    <Box
                      tabIndex={1}
                      as="button"
                      width="100%"
                      onClick={() => {
                        setDrawerId(item.sys.id)
                        createUtagLinkClickEvent({
                          linkLabel: item.title ?? '',
                          linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                        })
                      }}
                      color="white"
                      _hover={{
                        color: 'navHover',
                      }}
                      transition="all"
                      transitionDuration="200ms"
                      {...props}
                    >
                      <Flex width="100%" alignItems="center" justifyContent="space-between" py={4}>
                        <Box
                          as="span"
                          fontFamily="enz700"
                          fontSize="16px"
                          lineHeight="28px"
                          borderBottom={itemHasActiveLink ? 'solid 2px' : 'none'}
                          borderColor="surfaceBlue"
                        >
                          {item.title}
                        </Box>
                        <Box
                          h="32px"
                          w="32px"
                          sx={{
                            'html[lang=ar] &': {
                              transform: ['rotate(180deg)'],
                            },
                          }}
                        >
                          <ChevronCircleLarge />
                        </Box>
                      </Flex>
                    </Box>
                    <Divider m={0} color="white" opacity={0.24} />
                  </Box>
                </Fragment>
              )
            })}
          </Flex>
          <Flex flexDirection="column" gap={4}>
            <ProfileMenuMobile preview={preview} locale={locale} />
            <LocaleMenuMobile />
            <NavLinks
              locale={locale}
              preview={preview}
              codeId={CONTENT_SERVICE.SECONDARY_TOP_NAV}
            />
          </Flex>
        </Flex>
        <Drawer
          isOpen={drawerId !== null}
          onClose={onClose}
          placement={drawerPlacement}
          size={DRAWER_SIZE.FULL_SCREEN}
        >
          <DrawerContent
            bg="deepblue.500"
            color="white"
            top="75px !important"
            pb="75px"
            maxH="100%"
            height="100%"
            overflowY="scroll"
          >
            <DrawerBody pb="32px">
              {drawerItem ? (
                <Flex flexDir="column">
                  <BackButton onClick={closeNestedDrawer} />
                  <Flex flexDir="column" gap={6}>
                    {(drawerItem.title || drawerItem.sectionDescription) && (
                      <Flex flexDir="column" gap={4}>
                        <Divider m={0} color="white" opacity={0.24} />
                        <NavIntroduction
                          title={drawerItem.title}
                          description={drawerItem.sectionDescription}
                        />
                      </Flex>
                    )}
                    {drawerItem.quickLinksTitle &&
                    drawerItem.quickLinksCollection &&
                    drawerItem.quickLinksCollection.items.length > 0 ? (
                      <Flex flexDir="column" gap={4}>
                        <Divider m={0} color="white" opacity={0.24} />
                        <NavQuickLinks
                          title={drawerItem.quickLinksTitle}
                          links={createLinks({ linkItems: drawerItem.quickLinksCollection })}
                        />
                      </Flex>
                    ) : null}
                    {drawerItem.navigationElementsCollection &&
                    drawerItem.navigationElementsCollection.items &&
                    drawerItem.navigationElementsCollection.items.length > 0 ? (
                      <Flex flexDir="column" gap={4}>
                        <Divider m={0} color="white" opacity={0.24} />
                        <NavAccordion
                          links={createLinks({
                            linkItems: drawerItem.navigationElementsCollection,
                          })}
                        />
                      </Flex>
                    ) : null}
                    {drawerItem.featuredStoryCollection &&
                    drawerItem.featuredStoryCollection.items &&
                    drawerItem.featuredStoryCollection.items.length > 0 ? (
                      <Flex flexDir="column" gap={4}>
                        <Divider m={0} color="white" opacity={0.24} />
                        <NavFeaturedStories stories={drawerItem.featuredStoryCollection.items} />
                      </Flex>
                    ) : null}
                    {isAuthenticated && drawerItem.ctaBannerLoggedIn ? (
                      <NavCtaBanner {...drawerItem.ctaBannerLoggedIn} />
                    ) : drawerItem.ctaBannerDefault ? (
                      <NavCtaBanner {...drawerItem.ctaBannerDefault} />
                    ) : null}
                  </Flex>
                </Flex>
              ) : null}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </NavDrawer>
    </Box>
  )
}

export default MobileNav
