import {
  NaumaiPageSlugs_Swnz_NaumaiPageStandardFragment,
  PageSlugs_Swnz_PageFragment,
  Page_Swnz_PageFragment,
  ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment,
  ParentPagePath_Swnz_PageFragment
} from 'content-service'

export interface PageResults {
  pageResult:
    | ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment
    | ParentPagePath_Swnz_PageFragment
    | null
}

export interface PageWhereQueryInput {
  slug: string
  parentPage?: PageWhereQueryInput
}
export interface PageQueryInput {
  where?: PageWhereQueryInput | undefined
}
interface BuildPathInput {
  slugs: Array<string> | null
  params?: string
  anchor?: string
}
interface PageExistsProps {
  page: Page_Swnz_PageFragment
  params: { slug?: string | string[] }
}

export const addPageSlugToArr = (
  arr: Array<string>,
  {
    slug,
    parentPage
  }:
    | PageSlugs_Swnz_PageFragment
    | NaumaiPageSlugs_Swnz_NaumaiPageStandardFragment
) => {
  if (!slug) {
    return
  }

  if (slug !== '/') {
    arr.unshift(slug)
  }

  if (parentPage) {
    addPageSlugToArr(arr, parentPage)
  } else {
    return
  }
}

export const orderSlugs = ({ pageResult }: PageResults): Array<string> => {
  if (!pageResult) {
    return []
  }

  const { slug = null, parentPage = null } = pageResult
  const slugArr = slug ? [slug] : []

  return [...orderSlugs({ pageResult: parentPage }), ...slugArr]
}

export const buildPath = ({
  slugs,
  params,
  anchor
}: BuildPathInput): string => {
  if (!slugs) {
    return ''
  }

  let url = slugs.filter((slug) => slug !== '/').join('/')

  if (params) {
    url = `${url}?${encodeURIComponent(params)}`
  }

  if (anchor) {
    url = `${url}#${anchor}`
  }

  return `/${url}`
}

export const buildPageQueryInput = (
  slugs: string | Array<string>
): PageQueryInput => {
  if (!slugs || slugs.length === 0) {
    return {}
  }

  const slug = typeof slugs === 'string' ? slugs : slugs.slice(-1).pop()

  if (!slug) {
    return {}
  }

  return {
    where: { slug }
  }
}

// Get the slug for the current page from a url path
export const getSlug = (slug: string | string[] | undefined): string => {
  if (slug === undefined) {
    return ''
  }

  if (typeof slug === 'string') {
    return slug
  }

  const [pageSlug] = slug.slice(-1)
  return pageSlug
}

/**
 * Check to see if the slug is in the given list of blocked slugs
 * @param slug
 * @param blockList
 * @returns boolean
 */

export enum MatchType {
  PARTIAL = 'partial',
  FULL = 'full'
}

export const pageExists = ({ page, params }: PageExistsProps): boolean => {
  const pagePath = orderSlugs({ pageResult: page }).slice(1)
  const currentPage =
    typeof params?.slug === 'string' ? [params?.slug] : params?.slug ?? []
  return pagePath.every((item, index) => item === currentPage[index])
}

/**
 * Create a slug from string
 *
 * @param args string
 * @returns string
 */
export const slugify = (inputString: string | number | undefined): string => {
  if (!inputString) {
    return ''
  }

  return [inputString]
    .join(' ')
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}
