import { Box, ButtonGroup, Center, Divider } from '@chakra-ui/react'
import { Tag_Swnz_TagFragment } from 'content-service'
import { Fragment, useState } from 'react'
import { Button } from '../../buttons'
import { TAG_FILTERS } from './helpers/constants'

interface RenderFilterButtonsProps {
  colorVar: string | undefined
  tags: Tag_Swnz_TagFragment[]
  onClickHandler: (tag: Tag_Swnz_TagFragment) => void
}

const RenderFilterButtons = ({
  colorVar,
  tags,
  onClickHandler
}: RenderFilterButtonsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const pseudoElementCSS = {
    backgroundColor: 'white',
    color: `var(${colorVar})`
  }

  // Prepend tags array with 'All Areas' button
  tags.unshift(TAG_FILTERS.allAreas as Tag_Swnz_TagFragment)

  return (
    <Center marginBottom={{ base: 8, lg: 10 }} marginX={6}>
      <Box
        data-testid='card_carousel_tag_filters'
        overflowX={{ base: 'scroll', lg: 'hidden' }}
        whiteSpace='nowrap'
        overflowY='hidden'
      >
        <ButtonGroup spacing={4} paddingY={6}>
          {tags.map((tag, index) => (
            <Fragment key={index}>
              <Button
                variant='monochrome'
                color='white'
                _active={pseudoElementCSS}
                _hover={pseudoElementCSS}
                aria-label={`Filter by ${tag.label}`}
                title={tag.label ?? tag.key ?? ''}
                onClick={() => {
                  setActiveIndex(index), onClickHandler(tag)
                }}
                isActive={index === activeIndex ? true : false}
              >
                {tag.label}
              </Button>
              {index === 0 && (
                <Divider orientation='vertical' height='auto' marginY='0' />
              )}
            </Fragment>
          ))}
        </ButtonGroup>
      </Box>
    </Center>
  )
}

export default RenderFilterButtons
