import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import React, { useRef } from 'react'
import {
  connectMenu,
  Hit,
  MenuExposed,
  MenuProvided
} from 'react-instantsearch-core'

type Item = Pick<Hit, 'label' | 'value' | 'key'>

type FinderFacetMenuItem = {
  label: string
  key: string
  value: string
}

type FinderFacetMenuProps = MenuProvided &
  MenuExposed &
  BoxProps & {
    heading: string
    placeholder?: string
    facets?: FinderFacetMenuItem[] | Item[]
    disabled?: boolean
  }

const FinderFacetMenu = connectMenu(
  ({
    heading,
    placeholder = '',
    facets = [],
    items = [],
    currentRefinement,
    refine,
    disabled = false
  }: FinderFacetMenuProps) => {
    const PLACEHOLDER_VALUE = 'noop'

    const menuItems = [
      ...(placeholder
        ? [{ label: placeholder, key: '', value: PLACEHOLDER_VALUE }]
        : []),
      ...(facets?.length ? facets : items)
    ]

    let buttonLabel = menuItems?.[0]?.label ?? ''

    /*
    If there's a current refinement, we want to find the matching menu item and set buttonLabel
    to the menu item's label. If we can't find the menu item's label, we'll set buttonLabel to the
    current refinement, which is the menu item's value.
    */
    if (currentRefinement) {
      buttonLabel =
        menuItems.find((menuItem) => menuItem.value === currentRefinement)
          ?.label ?? currentRefinement
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      const { refinement = '' } = event.currentTarget.dataset
      const valueToRefine = refinement === PLACEHOLDER_VALUE ? null : refinement
      event.preventDefault()
      refine(valueToRefine)
    }

    const inputRef = useRef<HTMLDivElement>(null)

    return (
      <Box
        borderRadius='base'
        backgroundColor='deepblue.400'
        borderColor='teal.200'
        borderWidth='1px'
        borderStyle='solid'
        ref={inputRef}
        sx={{
          border: 0,
          '> div': {
            boxShadow: '0px 2px 10px 0px rgb(0 0 0 / 15%)',
            borderRadius: 'base'
          }
        }}
      >
        <Menu isLazy matchWidth={true} flip={false}>
          <MenuButton
            as={Button}
            variant='unstyled'
            border='2px solid'
            borderColor='transparent'
            borderRadius='base'
            width='full'
            display='flex'
            justifyContent='space-between'
            rightIcon={
              <Box
                transform='rotate(90deg)'
                boxSize='30px'
                color='deepblue.500'
              >
                <ChevronIcon />
              </Box>
            }
            color='deepblue.500'
            fontFamily='enz400'
            fontSize='16px'
            height='60px'
            paddingLeft={4}
            paddingRight={3}
            padding='0'
            overflow='hidden'
            _active={{
              borderColor: 'teal.500',
              borderRadius: 'base',
              transition: '0.2s all',
              svg: {
                color: 'teal.500'
              }
            }}
            sx={{
              'span:first-of-type': {
                display: 'block',
                overflow: 'hidden',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }
            }}
            isDisabled={disabled}
          >
            <Box
              as='span'
              aria-label={`${heading} | ${buttonLabel}`}
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              height='full'
              lineHeight='1.25'
            >
              <Box as='span' color='teal.500' fontSize='12px'>
                {heading}
              </Box>
              {buttonLabel}
            </Box>
          </MenuButton>
          <MenuList
            border='none'
            boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
            paddingX='12px'
            paddingBottom='12px'
            maxHeight={{ base: '290px', md: '420px' }}
            height='auto'
            overflowY='scroll'
          >
            <MenuOptionGroup>
              {menuItems.map((item, index) => (
                <MenuItem
                  value={item.value}
                  data-refinement={item.value}
                  aria-label={item.label}
                  onClick={handleClick}
                  key={index}
                  borderRadius='base'
                  _hover={{ background: 'deepblue.400' }}
                  paddingX='4px'
                >
                  {item.label}
                </MenuItem>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    )
  }
)

export type { FinderFacetMenuItem }
export default FinderFacetMenu
