import { NavLink } from 'ui'

const findIndexWithActiveLink = (
  links: NavLink[],
  currentPath: string,
  options?: {
    checkTopLevelLink: boolean
  }
): number[] | undefined => {
  for (let i = 0; i < links.length; i++) {
    const link = links[i]

    if (options?.checkTopLevelLink && link.slug === currentPath) {
      return [i]
    }

    for (let j = 0; j < (link.children || []).length; j++) {
      const child = link.children![j]

      if (child.slug === currentPath) {
        return [i] // Return top-level index of link
      }

      if (child.children) {
        for (let k = 0; k < child.children.length; k++) {
          const grandchild = child.children[k]

          if (grandchild.slug === currentPath) {
            return [i] // Return top-level index of link
          }
        }
      }
    }
  }

  return undefined
}

export default findIndexWithActiveLink
