import SUPPORTED_LANGUAGES from './supportedLanguages'

const ALGOLIA_INVENTORY_INDICIES = {
  COURSE_FINDER: {
    MAIN: 'course',
    ASCENDING: 'course_ascending',
    DESCENDING: 'course_descending',
  },
  PROVIDER_FINDER: {
    MAIN: 'provider',
    ASCENDING: 'provider_ascending',
    DESCENDING: 'provider_descending',
  },
  SCHOLARSHIP_FINDER: {
    MAIN: 'scholarship',
    ASCENDING: 'scholarship_ascending',
    DESCENDING: 'scholarship_descending',
  },
}

const ALGOLIA_INDICES = {
  ...ALGOLIA_INVENTORY_INDICIES,
  SITE: Object.fromEntries(
    SUPPORTED_LANGUAGES.map((language) => [language.locale, `site-pages-${language.locale}`])
  ),
  EMPTY_RECORD_TITLE: 'Homepage',
}

export default ALGOLIA_INDICES
export { ALGOLIA_INVENTORY_INDICIES }
