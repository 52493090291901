import {
  AutocompletePlugin,
  HighlightHitParams
} from '@algolia/autocomplete-js'
import { getAlgoliaFacets } from '@algolia/autocomplete-preset-algolia'
import { SearchClient } from 'algoliasearch/lite'
import { searchStateToURL } from 'utils/src/helpers/algoliaSearch'
import Highlight from '../../Highlight'
import { pluginOnSelect } from '../helpers'
import { addItemType } from '../helpers/addItemType'
import { buildState } from '../helpers/buildState'
import { SearchAutocompleteContext } from '../SearchAutocomplete'
import { BaseResult, FacetHit, SearchType } from '../types'
interface CreateFacetQuery {
  indexName: string
  facet: string
  resultsPagePath: string
  searchClient: SearchClient
}

export interface FacetQueryResult extends BaseResult {
  _highlightResult: HighlightHitParams<BaseResult>
}

export function pathwaysCountriesFacetPlugin({
  searchClient,
  indexName,
  facet,
  resultsPagePath
}: CreateFacetQuery): AutocompletePlugin<FacetQueryResult, FacetQueryResult> {
  return {
    getSources({ query }) {
      if (!query) {
        return []
      }

      return [
        {
          sourceId: indexName,
          getItems({ query }) {
            return getAlgoliaFacets({
              searchClient,
              queries: [
                {
                  type: 'facet',
                  indexName,
                  facet,
                  params: {
                    facetQuery: query
                  }
                }
              ],
              transformResponse({ facetHits }) {
                return [
                  addItemType<FacetHit>({
                    type: SearchType.FACET,
                    items: facetHits.flat()
                  })
                ]
              }
            })
          },
          getItemUrl({ item, state }) {
            const { instantSearchState } =
              state.context as SearchAutocompleteContext
            const url = searchStateToURL(
              resultsPagePath,
              buildState({
                searchState: instantSearchState ?? {},
                facet,
                facetValue: item.label,
                query: ''
              })
            )

            return url
          },
          getItemInputValue({ item }) {
            return item?.label ?? ''
          },
          onSelect(params) {
            pluginOnSelect(params)
          },
          templates: {
            header() {
              return 'Suggested searches'
            },
            item({ item }) {
              return <Highlight attribute='label' hit={item} />
            }
          }
        }
      ]
    }
  }
}
