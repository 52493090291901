export type SupportedLanguage = {
  title: string
  locale: string
}

const SUPPORTED_LANGUAGES: SupportedLanguage[] = [
  {
    title: 'English',
    locale: 'en',
  },
  {
    title: '简体中文',
    locale: 'cn',
  },
  {
    title: '日本語',
    locale: 'ja',
  },
  {
    title: '한국어',
    locale: 'ko',
  },
  {
    title: 'ภาษาไทย',
    locale: 'th',
  },
  {
    title: 'Tiếng Việt',
    locale: 'vi',
  },
  {
    title: 'Português',
    locale: 'pt',
  },
  {
    title: 'Español',
    locale: 'es',
  },
  {
    title: 'اَلْعَرَبِيَّةُ',
    locale: 'ar',
  },
]

export default SUPPORTED_LANGUAGES
