
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /// utils/src/@polyfills should always be at the top
import { ApolloProvider } from '@apollo/client';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useApollo } from 'content-service';
import { DefaultSeo } from 'next-seo';
import type { AppProps as DefaultAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ErrorProps } from 'next/error';
import Router from 'next/router';
import Script from 'next/script';
// third party css has to be imported directly into _app
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-phone-input-2/lib/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SiteSearchProvider } from 'src/components/Search/SiteSearch/SiteSearch';
import { LEAD_CAPTURE_FORM } from 'src/constants/leadCapture';
import { TealiumProvider } from 'tracking';
import { StyleProviderWrapper, Tohu } from 'ui';
import 'utils/src/@polyfills';
import FirstTimeLoginModal from '../components/FirstTimeLoginModal';
import { DEFAULT_SEO, ROUTES, SUPRESS_POPUP_SLUGS } from '../constants';
import { UserProvider } from '../context';
import GlobalLayout from '../layouts/GlobalLayout';
import Auth0LoginWrapper from '../wrappers/Auth0LoginWrapper';
type AppProps = DefaultAppProps & {
    pageProps: {
        preview: boolean;
    };
    err: ErrorProps;
};
const onRedirectCallback = (appState?: AppState) => {
    const base = process.env.NEXT_PUBLIC_URL ?? '';
    let returnTo = new URL(appState?.returnTo ?? '', base);
    if (appState?.returnTo === undefined || returnTo.pathname === ROUTES.AUTH.USER_CONSENT) {
        returnTo = new URL(ROUTES.HOME, base);
    }
    Router.replace(base, returnTo, { locale: appState?.locale });
};
function TealiumAuth0Wrapper({ children }: {
    children: React.ReactNode;
}) {
    const auth0 = useAuth0();
    return <TealiumProvider auth0={auth0}>{children}</TealiumProvider>;
}
function allowLeadCaptureForm({ showLcf, locale }: {
    showLcf: boolean;
    locale: string;
}) {
    return showLcf && !LEAD_CAPTURE_FORM.BLOCKED_LOCALES.includes(locale);
}
const LeadCaptureFormPopup = dynamic(() => import('swnz/src/components/forms/LeadCaptureFormPopup'), {
    ssr: false
});
function App({ Component, pageProps, err }: AppProps) {
    const { preview, slug, showLcf, locale } = pageProps;
    const apolloClient = useApollo(pageProps);
    return (<>
      <Script id="swnz-utag-init-script" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
              window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
              window.utag_cfg_ovrd.noview = true;
              (function(a,b,c,d){
                a="${process.env.NEXT_PUBLIC_TEALIUM_URL ?? ''}";
                b=document;c='script';d=b.createElement(c);
                d.onload=function() { b.dispatchEvent(new Event("utagLoaded")); };
                d.src=a;d.type='text/java'+c;d.async=true;
                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
              })();
            `
        }}/>

      <ApolloProvider client={apolloClient}>
        <SiteSearchProvider preview={preview} locale={locale}>
          <Auth0Provider domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ''} clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''} onRedirectCallback={onRedirectCallback} authorizationParams={{
            redirect_uri: typeof window !== 'undefined' ? window.location.origin : '',
            audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? '',
            scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE ?? ''
        }}>
            <UserProvider>
              <Auth0LoginWrapper>
                <TealiumAuth0Wrapper>
                  <StyleProviderWrapper>
                    <GlobalLayout preview={preview} locale={locale}>
                      <DefaultSeo {...DEFAULT_SEO}/>
                      <Component {...pageProps} err={err}/>
                      <>
                        {SUPRESS_POPUP_SLUGS.TOHU.includes(slug) ? null : <Tohu />}
                        {allowLeadCaptureForm({ showLcf, locale }) ? (<LeadCaptureFormPopup />) : null}
                      </>
                      <FirstTimeLoginModal />
                    </GlobalLayout>
                  </StyleProviderWrapper>
                </TealiumAuth0Wrapper>
              </Auth0LoginWrapper>
            </UserProvider>
          </Auth0Provider>
        </SiteSearchProvider>
      </ApolloProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  