import { Box, Flex, Spacer } from '@chakra-ui/react'
import { VerticalThinkNewNZLogo } from 'icons'
import { ReactElement } from 'react'
import Headroom from 'react-headroom'
import { ZINDEX } from 'utils'
import { Link } from '../Link'

export interface HeaderProps {
  rightHandContent: ReactElement
}

const Header = ({ rightHandContent }: HeaderProps) => {
  return (
    <Headroom style={{ zIndex: ZINDEX.HEADER }}>
      <Box bg='deepblue.500' as='header' dir='ltr'>
        <Flex
          maxW={{ base: 'container.xl', xxl: 'none' }}
          m='auto'
          color='white'
          align='center'
          py={{ base: 4, xl: 2 }}
          pl={{ base: 6, xl: 16 }}
          pr={{ base: 4, xl: 16 }}
        >
          <Link dataTestid='header-logo-link' href='/'>
            <Box
              h={{ base: '44px', sm: '72px' }}
              w={{ base: '112px', sm: '154px' }}
              color='white'
              sx={{
                '>svg': {
                  margin: 'auto 0',
                  display: 'block',
                  width: '100%',
                  height: '100%'
                }
              }}
            >
              <VerticalThinkNewNZLogo />
            </Box>
          </Link>
          <Spacer />
          {rightHandContent}
        </Flex>
      </Box>
    </Headroom>
  )
}

export default Header
