import { AutocompletePlugin } from '@algolia/autocomplete-js'
import Highlight from '../../Highlight'
import { addItemType } from '../helpers/addItemType'
import { BaseResult, SearchType } from '../types'

export function swnzQuickLinksPlugin(
  swnzQuickLinksArray: BaseResult[]
): AutocompletePlugin<BaseResult, BaseResult> {
  return {
    getSources({ query }) {
      if (query) {
        return []
      }

      return [
        {
          sourceId: 'swnzQuickLinks',
          getItems() {
            return addItemType({
              type: SearchType.FACET,
              items: swnzQuickLinksArray
            })
          },
          getItemUrl({ item }) {
            return item.url
          },
          getItemInputValue({ item }) {
            return item?.label ?? ''
          },
          templates: {
            item({ item }) {
              return <Highlight attribute='label' hit={item} />
            }
          }
        }
      ]
    }
  }
}
