import { Box } from '@chakra-ui/react'
import { NavLink } from '../NavItem'
import { Header } from './Header'
import { NavCollapsible } from './NavCollapsible'

interface MainNavProps {
  links: NavLink[]
  callback: (id?: string) => void
}

export const MainNav = ({ links, callback }: MainNavProps) => {
  return (
    <Box borderTop='2px solid rgba(57, 71, 95)'>
      <Header />
      <NavCollapsible links={links} callback={callback} />
    </Box>
  )
}
