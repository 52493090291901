import { ReactElement } from 'react'
import { PreviewBanner } from 'ui'
import Footer from '../components/Footer'
import Header from '../components/Header'

export interface LayoutProps {
  preview: boolean
  children: ReactElement | ReactElement[]
  locale: string
}

const GlobalLayout = ({ preview, children, locale }: LayoutProps) => (
  <>
    <PreviewBanner preview={preview} />
    <Header preview={preview} locale={locale} />
    {children}
    <Footer preview={preview} />
  </>
)

export default GlobalLayout
