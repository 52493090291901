const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  RECOMMENDATIONS: '/dashboard#recommendations',
  ENQUIRIES: '/dashboard#enquired',
  FAVOURITES: '/dashboard#favourites',
  PROFILE: '/dashboard#profile',
  COURSE: {
    RESULTS: '/study-options/course/results',
    DETAIL: '/study-options/course',
  },
  PROVIDER: {
    RESULTS: '/study-options/education-provider/results',
    DETAIL: '/study-options/education-provider',
  },
  SCHOLARSHIP: {
    RESULTS: '/study-options/scholarship/results',
    DETAIL: '/study-options/scholarship',
  },
  AUTH: {
    REDIRECT: '/en/auth-redirect',
    EMAIL_UNVERIFIED: '/en/email-unverified',
    LOGIN: '/login',
    USER_CONSENT: '/en/user-consent',
  },
  SITE_SEARCH: '/search',
}

export default ROUTES
