import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'
import { useIdle } from 'utils'
import useLogin from '../hooks/useLogin'

interface Auth0LoginWrapperProps {
  children: ReactElement | ReactElement[]
}

// Try to log in or sign up a user if the login or signup param is in the URL
function useAutoLogin() {
  const logUserIn = useLogin()
  const router = useRouter()
  const { login, signup } = router.query

  useEffect(() => {
    if (!router.isReady || (login !== 'true' && signup !== 'true')) {
      return
    }

    const returnTo = new URL(router.asPath, process.env.NEXT_PUBLIC_URL)

    // Ensure we don't keep redirecting to the login or signup screen
    returnTo.searchParams.delete(login ? 'login' : 'signup')

    // When returning back from resetting the password the url contains
    // an `iss` param. This param isn't used so it can be removed
    returnTo.searchParams.delete('iss')

    // Replace the URL to ensure we don't keep redirecting to the login
    // or signup screen if the user clicks the browser back button
    const query = { ...router.query }
    if (query.login) delete query.login
    if (query.signup) delete query.signup
    if (query.iss) delete query.iss
    router.replace({ pathname: router.pathname, query }, undefined, { shallow: true })

    logUserIn(login ? 'login' : 'signup', undefined, returnTo.href)
  }, [router, logUserIn, login, signup])
}

function useAutoLogout() {
  const ONE_HOUR_IN_MS = 1000 * 60 * 60 * 1
  const { isAuthenticated, logout } = useAuth0()

  const { isIdle, running, setRunning } = useIdle({
    timeToIdle: ONE_HOUR_IN_MS,
    inactivityEvents: [],
  })

  useEffect(() => {
    setRunning(isAuthenticated)

    if (isIdle && isAuthenticated && running) {
      logout({
        logoutParams: {
          returnTo: process.env.NEXT_PUBLIC_URL ?? '',
        },
      })
    }
  }, [isAuthenticated, isIdle, logout, running, setRunning])
}

// If we have the login or signup param present on any url trigger the login or sign up page
const Auth0LoginWrapper = ({ children }: Auth0LoginWrapperProps) => {
  useAutoLogin()
  useAutoLogout()

  return <>{children}</>
}

export default Auth0LoginWrapper
