import { AutocompletePlugin } from '@algolia/autocomplete-js'
import { ROUTES } from 'swnz/src/constants'
import { searchStateToURL } from 'utils/src/helpers/algoliaSearch'
import Highlight from '../../Highlight'
import { pluginOnSelect } from '../helpers'
import { addItemType } from '../helpers/addItemType'
import { buildState } from '../helpers/buildState'
import { SearchAutocompleteContext } from '../SearchAutocomplete'
import { BaseResult, SearchType } from '../types'

const popularSearchesArray: BaseResult[] = [
  {
    label: 'Engineering',
    objectID: 'Engineering'
  },
  {
    label: 'Business',
    objectID: 'Business'
  },
  {
    label: 'Psychology',
    objectID: 'Psychology'
  },
  {
    label: 'Health and Medicine',
    objectID: 'Health and Medicine'
  },
  {
    label: 'Computer Science',
    objectID: 'Computer Science'
  }
]

export const popularCourseCategories: AutocompletePlugin<
  BaseResult,
  BaseResult
> = {
  getSources({ query }) {
    if (query) {
      return []
    }

    return [
      {
        sourceId: 'default',
        getItems() {
          return addItemType({
            type: SearchType.QUERY,
            items: popularSearchesArray
          })
        },
        getItemUrl({ item, state }) {
          const { instantSearchState } =
            state.context as SearchAutocompleteContext
          const url = searchStateToURL(
            ROUTES.COURSE.RESULTS,
            buildState({
              searchState: instantSearchState ?? {},
              query: item.label
            })
          )
          return url
        },
        getItemInputValue({ item }) {
          return item?.label ?? ''
        },
        onSelect(params) {
          pluginOnSelect(params)
        },
        templates: {
          header() {
            return 'Popular searches'
          },
          item({ item }) {
            return <Highlight attribute='label' hit={item} />
          }
        }
      }
    ]
  }
}
