import { Box } from '@chakra-ui/react'
import { CrossIcon, SearchIcon } from 'icons'
import { CtaButton, useHeaderDrawer } from 'ui'
import { useSiteSearchDrawer } from './SiteSearchDrawer'

const SiteSearchButton = () => {
  const { handleCloseNav, isOpen } = useHeaderDrawer()
  const { handleOpenSiteSearch, isSiteSearchOpen, handleCloseSiteSearch } = useSiteSearchDrawer()

  const handleOpenSearch = () => {
    if (isOpen) {
      handleCloseNav(false)
    }
    handleOpenSiteSearch()
  }

  return (
    <CtaButton
      tabIndex={1}
      borderRadius="full"
      border="none"
      boxSize={10}
      marginInlineEnd={{ base: '10px', lg: '0' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      aria-label={`${isSiteSearchOpen ? 'Close' : 'Open'} site search`}
      bg={isSiteSearchOpen ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}
      icon={
        <Box mt="2px" w="1em" h="1em" fontSize="30px">
          {isSiteSearchOpen ? <CrossIcon /> : <SearchIcon />}
        </Box>
      }
      onClick={isSiteSearchOpen ? handleCloseSiteSearch : handleOpenSearch}
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      }}
    >
      <></>
    </CtaButton>
  )
}

export default SiteSearchButton
