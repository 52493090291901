import {
  StatsImageCarousel_Swnz_StatsImageCarouselFragment,
  StatsImageCarousel_Swnz_StatsImageCarouselFragmentDoc
} from 'content-service'
import { isPresent } from 'ts-is-present'
import { useFragment } from 'utils'
import StatsImageCarousel from '../../components/StatsImageCarousel/StatsImageCarousel'
import { Connector } from '../types'

export type StatsImageCarouselConnectProps = Connector

const StatsImageCarouselConnect = ({
  id,
  typename
}: StatsImageCarouselConnectProps) => {
  const statsImageCarousel =
    useFragment<StatsImageCarousel_Swnz_StatsImageCarouselFragment>({
      id,
      typename,
      fragment: StatsImageCarousel_Swnz_StatsImageCarouselFragmentDoc,
      fragmentName: 'statsImageCarousel_SWNZ_StatsImageCarousel'
    })

  if (!statsImageCarousel) {
    return null
  }

  const images =
    statsImageCarousel.statCardImagesCollection?.items.filter(isPresent) ?? []

  if (!images || images.length === 0) return null

  return <StatsImageCarousel title={statsImageCarousel.title} images={images} />
}

export default StatsImageCarouselConnect
