/* eslint-disable */ 
 /* 🤖 This is an generated file, do not alter it directly! 👮 */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SWNZ_AlternatingContentMediaItem": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_BlogPostHero": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_CallToActionInternalLink": [
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Page"
    ],
    "SWNZ_CardWithLinkLink": [
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Page"
    ],
    "SWNZ_Entry": [
      "SWNZ_AccordionContainer",
      "SWNZ_AccordionItem",
      "SWNZ_AlternatingContent",
      "SWNZ_AlternatingQuote",
      "SWNZ_Banner",
      "SWNZ_Blockquote",
      "SWNZ_BlogAuthor",
      "SWNZ_BlogCallToAction",
      "SWNZ_BlogCardWithLink",
      "SWNZ_BlogCategory",
      "SWNZ_BlogCustomCampaignTags",
      "SWNZ_BlogImpactText",
      "SWNZ_BlogPost",
      "SWNZ_BulletPoint",
      "SWNZ_BulletPointContainer",
      "SWNZ_CallToAction",
      "SWNZ_CardContainer",
      "SWNZ_CardWithLink",
      "SWNZ_CardWithModal",
      "SWNZ_ComponentStatic",
      "SWNZ_EmotionContainer",
      "SWNZ_EmotionItem",
      "SWNZ_EmotionItemResponse",
      "SWNZ_FormDropdown",
      "SWNZ_FormDropdownOption",
      "SWNZ_FormFieldCity",
      "SWNZ_FormLeadCapture",
      "SWNZ_GalleryCarousel",
      "SWNZ_GenericText",
      "SWNZ_GlobalSettings",
      "SWNZ_Glossary",
      "SWNZ_Hero",
      "SWNZ_HeroFeatured",
      "SWNZ_HeroFeaturedFloatingCard",
      "SWNZ_HeroTab",
      "SWNZ_HomepageCard",
      "SWNZ_HomepagePanel",
      "SWNZ_HomepageStoryPanel",
      "SWNZ_IconCallToActions",
      "SWNZ_ImpactText",
      "SWNZ_InfoBox",
      "SWNZ_IntroTextWithJumpMenu",
      "SWNZ_InvCourse",
      "SWNZ_InvInstitution",
      "SWNZ_JumpAhead",
      "SWNZ_KeepExploring",
      "SWNZ_MapContainer",
      "SWNZ_MediaAudio",
      "SWNZ_MediaIcon",
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo",
      "SWNZ_Migration",
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Navigation",
      "SWNZ_NavigationElement",
      "SWNZ_NavigationSection",
      "SWNZ_NextSteps",
      "SWNZ_NextStepsItem",
      "SWNZ_Page",
      "SWNZ_PageHomepage",
      "SWNZ_PageModal",
      "SWNZ_PageSearchResults",
      "SWNZ_PageStandard",
      "SWNZ_Promo",
      "SWNZ_RichMedia",
      "SWNZ_Seo",
      "SWNZ_Stat",
      "SWNZ_StatContainer",
      "SWNZ_StatsImageCarousel",
      "SWNZ_StoryCard",
      "SWNZ_StoryCardContainer",
      "SWNZ_Tag",
      "SWNZ_Tile",
      "SWNZ_TileContainer",
      "SWNZ_TranslationProject",
      "SWNZ_Translator",
      "SWNZ_Value"
    ],
    "SWNZ_GalleryCarouselMediaItemsItem": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_HeroFeaturedFloatingCardInternalLink": [
      "SWNZ_MediaVideo",
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Page"
    ],
    "SWNZ_HeroFeaturedMediaItem": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_HeroMediaItem": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_InvInstitutionImageCarouselItem": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_NaumaiPageStandardHero": [
      "SWNZ_Hero",
      "SWNZ_HeroFeatured"
    ],
    "SWNZ_NaumaiPageStandardIntro": [
      "SWNZ_GenericText",
      "SWNZ_IntroTextWithJumpMenu"
    ],
    "SWNZ_NaumaiPageStandardSectionsItem": [
      "SWNZ_AccordionContainer",
      "SWNZ_AlternatingContent",
      "SWNZ_AlternatingQuote",
      "SWNZ_Banner",
      "SWNZ_Blockquote",
      "SWNZ_BulletPointContainer",
      "SWNZ_CardContainer",
      "SWNZ_ComponentStatic",
      "SWNZ_EmotionContainer",
      "SWNZ_GalleryCarousel",
      "SWNZ_GenericText",
      "SWNZ_ImpactText",
      "SWNZ_JumpAhead",
      "SWNZ_KeepExploring",
      "SWNZ_MapContainer",
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo",
      "SWNZ_NextSteps",
      "SWNZ_TileContainer"
    ],
    "SWNZ_NavigationElementInternalPageLink": [
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Page"
    ],
    "SWNZ_NavigationNavigationElementsItem": [
      "SWNZ_NavigationElement",
      "SWNZ_NavigationSection"
    ],
    "SWNZ_PageContent": [
      "SWNZ_PageHomepage",
      "SWNZ_PageModal",
      "SWNZ_PageSearchResults",
      "SWNZ_PageStandard"
    ],
    "SWNZ_PageHomepageSectionsItem": [
      "SWNZ_CardContainer",
      "SWNZ_HomepagePanel",
      "SWNZ_HomepageStoryPanel"
    ],
    "SWNZ_PageModalHero": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ_PageModalSectionsItem": [
      "SWNZ_BulletPointContainer",
      "SWNZ_GenericText",
      "SWNZ_ImpactText",
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo",
      "SWNZ_Value"
    ],
    "SWNZ_PageStandardHero": [
      "SWNZ_Hero",
      "SWNZ_HeroFeatured"
    ],
    "SWNZ_PageStandardIntro": [
      "SWNZ_GenericText",
      "SWNZ_IntroTextWithJumpMenu"
    ],
    "SWNZ_PageStandardSectionsItem": [
      "SWNZ_AccordionContainer",
      "SWNZ_AlternatingContent",
      "SWNZ_AlternatingQuote",
      "SWNZ_Banner",
      "SWNZ_Blockquote",
      "SWNZ_BulletPointContainer",
      "SWNZ_CardContainer",
      "SWNZ_ComponentStatic",
      "SWNZ_FormLeadCapture",
      "SWNZ_GalleryCarousel",
      "SWNZ_GenericText",
      "SWNZ_ImpactText",
      "SWNZ_JumpAhead",
      "SWNZ_KeepExploring",
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo",
      "SWNZ_NextSteps",
      "SWNZ_RichMedia",
      "SWNZ_StatContainer",
      "SWNZ_StatsImageCarousel",
      "SWNZ_StoryCardContainer",
      "SWNZ_TileContainer",
      "SWNZ_Value"
    ],
    "SWNZ_ResourceLink": [
      "SWNZ_AccordionItemBodyTextResourcesBlock",
      "SWNZ_AccordionItemBodyTextResourcesHyperlink",
      "SWNZ_AccordionItemBodyTextResourcesInline",
      "SWNZ_AlternatingContentBodyTextResourcesBlock",
      "SWNZ_AlternatingContentBodyTextResourcesHyperlink",
      "SWNZ_AlternatingContentBodyTextResourcesInline",
      "SWNZ_BannerBodyTextResourcesBlock",
      "SWNZ_BannerBodyTextResourcesHyperlink",
      "SWNZ_BannerBodyTextResourcesInline",
      "SWNZ_BlockquoteBodyTextResourcesBlock",
      "SWNZ_BlockquoteBodyTextResourcesHyperlink",
      "SWNZ_BlockquoteBodyTextResourcesInline",
      "SWNZ_BlogImpactTextBodyTextResourcesBlock",
      "SWNZ_BlogImpactTextBodyTextResourcesHyperlink",
      "SWNZ_BlogImpactTextBodyTextResourcesInline",
      "SWNZ_BlogPostBodyContentResourcesBlock",
      "SWNZ_BlogPostBodyContentResourcesHyperlink",
      "SWNZ_BlogPostBodyContentResourcesInline",
      "SWNZ_BulletPointBodyTextResourcesBlock",
      "SWNZ_BulletPointBodyTextResourcesHyperlink",
      "SWNZ_BulletPointBodyTextResourcesInline",
      "SWNZ_EmotionItemResponseBodyTextResourcesBlock",
      "SWNZ_EmotionItemResponseBodyTextResourcesHyperlink",
      "SWNZ_EmotionItemResponseBodyTextResourcesInline",
      "SWNZ_GenericTextBodyTextResourcesBlock",
      "SWNZ_GenericTextBodyTextResourcesHyperlink",
      "SWNZ_GenericTextBodyTextResourcesInline",
      "SWNZ_GlobalSettingsRichTextResourcesBlock",
      "SWNZ_GlobalSettingsRichTextResourcesHyperlink",
      "SWNZ_GlobalSettingsRichTextResourcesInline",
      "SWNZ_HeroTabStorySummaryOneResourcesBlock",
      "SWNZ_HeroTabStorySummaryOneResourcesHyperlink",
      "SWNZ_HeroTabStorySummaryOneResourcesInline",
      "SWNZ_HeroTabStorySummaryTwoResourcesBlock",
      "SWNZ_HeroTabStorySummaryTwoResourcesHyperlink",
      "SWNZ_HeroTabStorySummaryTwoResourcesInline",
      "SWNZ_HomepageCardBodyTextResourcesBlock",
      "SWNZ_HomepageCardBodyTextResourcesHyperlink",
      "SWNZ_HomepageCardBodyTextResourcesInline",
      "SWNZ_HomepagePanelFooterTextResourcesBlock",
      "SWNZ_HomepagePanelFooterTextResourcesHyperlink",
      "SWNZ_HomepagePanelFooterTextResourcesInline",
      "SWNZ_HomepagePanelIntroTextResourcesBlock",
      "SWNZ_HomepagePanelIntroTextResourcesHyperlink",
      "SWNZ_HomepagePanelIntroTextResourcesInline",
      "SWNZ_ImpactTextBodyTextResourcesBlock",
      "SWNZ_ImpactTextBodyTextResourcesHyperlink",
      "SWNZ_ImpactTextBodyTextResourcesInline",
      "SWNZ_InfoBoxInfoTextResourcesBlock",
      "SWNZ_InfoBoxInfoTextResourcesHyperlink",
      "SWNZ_InfoBoxInfoTextResourcesInline",
      "SWNZ_IntroTextWithJumpMenuBodyTextResourcesBlock",
      "SWNZ_IntroTextWithJumpMenuBodyTextResourcesHyperlink",
      "SWNZ_IntroTextWithJumpMenuBodyTextResourcesInline",
      "SWNZ_InvCourseDescriptionResourcesBlock",
      "SWNZ_InvCourseDescriptionResourcesHyperlink",
      "SWNZ_InvCourseDescriptionResourcesInline",
      "SWNZ_PageHomepageHeroWelcomeMessageResourcesBlock",
      "SWNZ_PageHomepageHeroWelcomeMessageResourcesHyperlink",
      "SWNZ_PageHomepageHeroWelcomeMessageResourcesInline",
      "SWNZ_StatBodyTextResourcesBlock",
      "SWNZ_StatBodyTextResourcesHyperlink",
      "SWNZ_StatBodyTextResourcesInline",
      "SWNZ_TileBodyTextResourcesBlock",
      "SWNZ_TileBodyTextResourcesHyperlink",
      "SWNZ_TileBodyTextResourcesInline",
      "SWNZ_ValueDrawerDescriptionResourcesBlock",
      "SWNZ_ValueDrawerDescriptionResourcesHyperlink",
      "SWNZ_ValueDrawerDescriptionResourcesInline"
    ],
    "SWNZ_RichMediaBackgroundMedia": [
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo"
    ],
    "SWNZ__Node": [
      "SWNZ_AccordionContainer",
      "SWNZ_AccordionItem",
      "SWNZ_AlternatingContent",
      "SWNZ_AlternatingQuote",
      "SWNZ_Banner",
      "SWNZ_Blockquote",
      "SWNZ_BlogAuthor",
      "SWNZ_BlogCallToAction",
      "SWNZ_BlogCardWithLink",
      "SWNZ_BlogCategory",
      "SWNZ_BlogCustomCampaignTags",
      "SWNZ_BlogImpactText",
      "SWNZ_BlogPost",
      "SWNZ_BulletPoint",
      "SWNZ_BulletPointContainer",
      "SWNZ_CallToAction",
      "SWNZ_CardContainer",
      "SWNZ_CardWithLink",
      "SWNZ_CardWithModal",
      "SWNZ_ComponentStatic",
      "SWNZ_EmotionContainer",
      "SWNZ_EmotionItem",
      "SWNZ_EmotionItemResponse",
      "SWNZ_FormDropdown",
      "SWNZ_FormDropdownOption",
      "SWNZ_FormFieldCity",
      "SWNZ_FormLeadCapture",
      "SWNZ_GalleryCarousel",
      "SWNZ_GenericText",
      "SWNZ_GlobalSettings",
      "SWNZ_Glossary",
      "SWNZ_Hero",
      "SWNZ_HeroFeatured",
      "SWNZ_HeroFeaturedFloatingCard",
      "SWNZ_HeroTab",
      "SWNZ_HomepageCard",
      "SWNZ_HomepagePanel",
      "SWNZ_HomepageStoryPanel",
      "SWNZ_IconCallToActions",
      "SWNZ_ImpactText",
      "SWNZ_InfoBox",
      "SWNZ_IntroTextWithJumpMenu",
      "SWNZ_InvCourse",
      "SWNZ_InvInstitution",
      "SWNZ_JumpAhead",
      "SWNZ_KeepExploring",
      "SWNZ_MapContainer",
      "SWNZ_MediaAudio",
      "SWNZ_MediaIcon",
      "SWNZ_MediaImage",
      "SWNZ_MediaVideo",
      "SWNZ_Migration",
      "SWNZ_NaumaiPageStandard",
      "SWNZ_Navigation",
      "SWNZ_NavigationElement",
      "SWNZ_NavigationSection",
      "SWNZ_NextSteps",
      "SWNZ_NextStepsItem",
      "SWNZ_Page",
      "SWNZ_PageHomepage",
      "SWNZ_PageModal",
      "SWNZ_PageSearchResults",
      "SWNZ_PageStandard",
      "SWNZ_Promo",
      "SWNZ_RichMedia",
      "SWNZ_Seo",
      "SWNZ_Stat",
      "SWNZ_StatContainer",
      "SWNZ_StatsImageCarousel",
      "SWNZ_StoryCard",
      "SWNZ_StoryCardContainer",
      "SWNZ_Tag",
      "SWNZ_Tile",
      "SWNZ_TileContainer",
      "SWNZ_TranslationProject",
      "SWNZ_Translator",
      "SWNZ_Value"
    ],
    "inventoryCollection": [
      "inventoryAgent",
      "inventoryCourse",
      "inventoryProvider",
      "inventoryScholarship"
    ]
  }
};
      export default result;
    