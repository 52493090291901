import { Tag_Swnz_TagFragment } from 'content-service'
import { ReactElement } from 'react'
import { Card, NavigationType } from '../CardCarouselGallery'
import { TAG_FILTERS } from './constants'

interface CreateCardCarouselGalleryCard {
  name?: string | undefined
  areaOfStudy?: string | undefined
  image: ReactElement | null
  location?: string | undefined
  videoUrl?: string | undefined
  tags?: Tag_Swnz_TagFragment[]
  modalId?: string | undefined
  title?: string | undefined
  isStoryCard?: boolean
  modalHasHeroVideo?: boolean
}

interface SetArrowIconOpacity {
  navigationType: NavigationType
  numberOfSlides: number
  currentSlide: number
  isMobile: boolean
}
interface SetNextArrowIconOpacity {
  numberOfSlides: number
  currentSlide: number
  reducedOpacityValue: number
  isMobile: boolean
}
interface SetPreviousArrowIconOpacity {
  currentSlide: number
  reducedOpacityValue: number
  isMobile: boolean
}

const createCardCarouselGalleryCard = ({
  name = '',
  areaOfStudy = '',
  image = null,
  location = '',
  videoUrl = '',
  tags,
  modalId = '',
  title = '',
  isStoryCard = false,
  modalHasHeroVideo = false
}: CreateCardCarouselGalleryCard): CreateCardCarouselGalleryCard => ({
  name,
  areaOfStudy,
  location,
  image,
  videoUrl,
  tags: tags || [],
  modalId,
  title,
  isStoryCard,
  modalHasHeroVideo
})

const createCardBottomValue = (
  index: number,
  isLargerThan600: boolean
): number => {
  if (isLargerThan600) {
    return index++ % 2 !== 1 ? 28 : 0
  }

  return 0
}

const setCenterSlidePosition = (
  isLargerThan960?: boolean,
  isLargerThan600?: boolean
): number => {
  if (isLargerThan960) {
    return 25
  }

  if (isLargerThan600) {
    return 40
  }

  return 85
}

const setNextArrowIconOpacity = ({
  numberOfSlides,
  currentSlide,
  reducedOpacityValue,
  isMobile
}: SetNextArrowIconOpacity) => {
  if (currentSlide === numberOfSlides - 1 && isMobile) {
    return reducedOpacityValue
  }

  if (currentSlide === numberOfSlides - 1 && !isMobile) {
    return reducedOpacityValue
  }

  if (currentSlide === numberOfSlides - 2 && !isMobile) {
    return reducedOpacityValue
  }

  if (currentSlide === numberOfSlides) {
    return reducedOpacityValue
  }

  return
}

const setPreviousArrowIconOpacity = ({
  currentSlide,
  reducedOpacityValue,
  isMobile
}: SetPreviousArrowIconOpacity) => {
  if (currentSlide === 0) {
    return reducedOpacityValue
  }

  if (currentSlide === 1 && !isMobile) {
    return reducedOpacityValue
  }

  return
}

const setArrowIconOpacity = ({
  navigationType,
  numberOfSlides,
  currentSlide,
  isMobile
}: SetArrowIconOpacity): number | undefined => {
  const reducedOpacityValue = 0.4

  if (numberOfSlides < 3 && isMobile) {
    return reducedOpacityValue
  }

  switch (navigationType) {
    case NavigationType.Next:
      return setNextArrowIconOpacity({
        numberOfSlides,
        currentSlide,
        reducedOpacityValue,
        isMobile
      })
    case NavigationType.Previous:
      return setPreviousArrowIconOpacity({
        currentSlide,
        reducedOpacityValue,
        isMobile
      })
    default:
      return 1
  }
}

const updateCardsBasedOnFilteredValue = ({
  currentTag,
  cards,
  isLargerThan600
}: {
  currentTag: Tag_Swnz_TagFragment
  cards: (Card | null)[]
  isLargerThan600?: boolean
}) => {
  let updatedCards: (Card | null)[]
  let numberOfNonNullSlides: number // needed for navigation opacity and next/previous business logic
  const currentSlide = isLargerThan600 ? 1 : 0 // slider starts at 2nd card on largers screens / 1st card on smallers screens (helps with offset design layout)

  if (currentTag.key !== TAG_FILTERS.allAreas.key) {
    updatedCards = cards.filter(
      (card) =>
        card?.tags &&
        card.tags.findIndex((tag) => tag?.key === currentTag.key) !== -1
    )

    numberOfNonNullSlides = updatedCards.length

    for (let index = 0; index < cards.length - updatedCards.length; index++) {
      updatedCards.push(null)
    }

    return {
      cards: updatedCards,
      currentSlide,
      numberOfSlides: numberOfNonNullSlides
    }
  }

  // 'All areas' (all categories)
  return {
    cards,
    currentSlide,
    numberOfSlides: cards.length
  }
}

export {
  createCardBottomValue,
  createCardCarouselGalleryCard,
  setArrowIconOpacity,
  setCenterSlidePosition,
  updateCardsBasedOnFilteredValue
}
export type { CreateCardCarouselGalleryCard }
