import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import {
  FlagARIcon,
  FlagCNIcon,
  FlagESIcon,
  FlagJAIcon,
  FlagKOIcon,
  FlagNZIcon,
  FlagPTIcon,
  FlagTHIcon,
  FlagVNIcon,
} from 'icons'
import { useRouter } from 'next/router'
import { SUPPORTED_LANGUAGES, SupportedLanguage } from '../../constants'

export const LOCALE_FLAG_MAP = {
  en: FlagNZIcon,
  cn: FlagCNIcon,
  ja: FlagJAIcon,
  ko: FlagKOIcon,
  th: FlagTHIcon,
  vi: FlagVNIcon,
  pt: FlagPTIcon,
  es: FlagESIcon,
  ar: FlagARIcon,
}

const LocaleMenuMobile = () => {
  const { asPath, locale } = useRouter()

  const currentLanguage =
    SUPPORTED_LANGUAGES.find((lang) => lang.locale === locale) ?? SUPPORTED_LANGUAGES[0]

  const setCookieOnRouteChange = (language: SupportedLanguage) => {
    document.cookie = `NEXT_LOCALE=${language.locale}`
    const basePath = `${window.location.origin}/${language?.locale}`
    const fullPath = asPath === '/' ? basePath : `${basePath}${asPath}`

    // [NOTE]: we must force a refresh of the page due to https://github.com/vyg/enz-study-with-new-zealand/issues/623
    window.location.href = fullPath
  }

  return (
    <Accordion allowToggle width="100%">
      <AccordionItem mb={0} border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              tabIndex={1}
              _hover={{ color: 'navHover', backgroundColor: 'initial' }}
              px={0}
              py="6px"
            >
              <Flex alignItems="center" gap="6px">
                <Icon as={LOCALE_FLAG_MAP[currentLanguage.locale]} width="18px" height="18px" />
                <Text m={0} fontSize="14px" lineHeight="20px" fontFamily="enz500">
                  {currentLanguage.title}
                </Text>
                <Icon
                  as={ChevronDownIcon}
                  transition="transform 0.2s"
                  transform={isExpanded ? 'rotate(180deg)' : ''}
                  color={isExpanded ? 'surfaceBlue' : ''}
                  width="18px"
                  height="18px"
                />
              </Flex>
            </AccordionButton>
            <AccordionPanel
              mt="4px"
              bg="deepblue.80"
              borderRadius="8px"
              px="0"
              pt="8px"
              pb="16px"
              boxShadow="0px 4px 16px 0 rgba(0,0,0,0.2)"
              display="flex"
              flexDir="column"
              gap="8px"
            >
              {SUPPORTED_LANGUAGES.map((language) => (
                <Box
                  key={language.title}
                  width="100%"
                  as="button"
                  tabIndex={1}
                  onClick={() => {
                    setCookieOnRouteChange(language)
                  }}
                  aria-label={`link to ${language.title}`}
                  title={`link to ${language.title} site`}
                  display="flex"
                  color="white"
                  _hover={{ color: 'navHover' }}
                  textDecor="none"
                  alignItems="center"
                  px="8px"
                >
                  <Flex
                    bg={language.locale === locale ? 'deepblue.350' : 'deepblue.80'}
                    width="100%"
                    borderRadius="8px"
                    alignItems="center"
                    justifyContent="left"
                    py="6px"
                    px="8px"
                    gap="6px"
                  >
                    <Icon as={LOCALE_FLAG_MAP[language.locale]} width="18px" height="18px" />
                    <Text m={0} fontSize="14px" lineHeight="20px" fontFamily="enz500">
                      {language.title}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

export default LocaleMenuMobile
