import ROUTES from './routes'

const SUPRESS_POPUP_SLUGS = {
  TOHU: ['new-zealand-education-fair-korea'],
}

const SLUG = {
  SEARCH_RESULTS_PAGE_SLUG: {
    COURSE: 'search-results-course',
    PROVIDER: 'search-results-institution',
    SCHOLARSHIP: 'search-results-scholarship',
    SITE: 'search',
  },
}

const SEARCH_RESULTS_PAGE_SLUG_MAP = {
  [SLUG.SEARCH_RESULTS_PAGE_SLUG.COURSE]: {
    path: ROUTES.COURSE.RESULTS,
    title: 'Find a course',
  },
  [SLUG.SEARCH_RESULTS_PAGE_SLUG.PROVIDER]: {
    path: ROUTES.PROVIDER.RESULTS,
    title: 'Find an education provider',
  },
  [SLUG.SEARCH_RESULTS_PAGE_SLUG.SCHOLARSHIP]: {
    path: ROUTES.SCHOLARSHIP.RESULTS,
    title: 'Find a scholarship',
  },
}

export default SLUG
export { SEARCH_RESULTS_PAGE_SLUG_MAP, SUPRESS_POPUP_SLUGS }
