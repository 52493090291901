import { Box, Flex, Text } from '@chakra-ui/react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, NavLink } from 'ui'

const NavQuickLinks = ({ title, links }: { title: string; links: NavLink[] }) => (
  <Flex flexDir="column" gap={2}>
    <Text fontFamily="enz400" fontSize="14px" lineHeight="20px" mb={0}>
      {title}
    </Text>
    <Flex as="ul" flexWrap="wrap" columnGap={4} rowGap={2}>
      {links.map(({ slug, id, ...link }) => (
        <Box
          as="li"
          key={id}
          listStyleType="none"
          position="relative"
          _notLast={{
            _after: {
              content: `""`,
              position: 'absolute',
              height: '20px',
              width: '1px',
              opacity: 0.24,
              backgroundColor: 'white',
              ml: 2,
              top: '50%',
              bottom: '50%',
              transform: 'translateY(-50%)',
            },
          }}
        >
          <Link
            onClick={() => {
              createUtagLinkClickEvent({
                linkLabel: link.title ?? '',
                linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
              })
            }}
            tabIndex={1}
            href={slug}
            textDecoration="none"
            color="white"
            _hover={{
              color: 'navHover',
            }}
            fontFamily="enz700"
            fontSize="16px"
            lineHeight="28px"
            {...link}
          >
            {link.title}
          </Link>
        </Box>
      ))}
    </Flex>
  </Flex>
)

export default NavQuickLinks
