import { ChevronDownIcon } from '@chakra-ui/icons'
import { Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { SUPPORTED_LANGUAGES, SupportedLanguage } from '../../constants'
import { LOCALE_FLAG_MAP } from './LocaleMenuMobile'

const LocaleMenuDesktop = () => {
  const { asPath, locale } = useRouter()

  const currentLanguage =
    SUPPORTED_LANGUAGES.find((language) => language.locale === locale) ?? SUPPORTED_LANGUAGES[0]

  const setCookieOnRouteChange = (language: SupportedLanguage) => {
    document.cookie = `NEXT_LOCALE=${language.locale}`
    const basePath = `${window.location.origin}/${language?.locale}`
    const fullPath = asPath === '/' ? basePath : `${basePath}${asPath}`

    // [NOTE]: we must force a refresh of the page due to https://github.com/vyg/enz-study-with-new-zealand/issues/623
    window.location.href = fullPath
  }

  return (
    <Menu offset={[-16, 8]}>
      {({ isOpen }) => (
        <>
          <MenuButton color="white" _hover={{ color: 'navHover' }} tabIndex={1} py="6px">
            <Flex alignItems="center" gap="6px">
              <Icon as={LOCALE_FLAG_MAP[currentLanguage.locale]} width="18px" height="18px" />
              <Text m={0} fontSize="14px" lineHeight="20px" fontFamily="enz500">
                {currentLanguage.title}
              </Text>
              <Icon
                as={ChevronDownIcon}
                transition="transform 0.2s"
                transform={isOpen ? 'rotate(180deg)' : ''}
                color={isOpen ? 'surfaceBlue' : ''}
                width="18px"
                height="18px"
              />
            </Flex>
          </MenuButton>
          <MenuList
            bg="deepblue.80"
            border="none"
            minW="fit-content"
            w="200px"
            display="flex"
            flexDir="column"
            gap="8px"
            pt="8px"
            pb="16px"
            px="8px"
            mt="-6px"
          >
            {SUPPORTED_LANGUAGES.map((language) => (
              <MenuItem
                tabIndex={1}
                onClick={() => {
                  setCookieOnRouteChange(language)
                }}
                aria-label={`link to ${language.title}`}
                title={`link to ${language.title} site`}
                display="flex"
                color="white"
                _hover={{ color: 'navHover' }}
                textDecor="none"
                key={language.title}
                alignItems="center"
                fontFamily="enz400"
                bg="deepblue.80"
                p={0}
              >
                <Flex
                  bg={language.locale === locale ? 'deepblue.350' : 'deepblue.80'}
                  width="100%"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="left"
                  py="6px"
                  px="8px"
                  gap="6px"
                >
                  <Icon as={LOCALE_FLAG_MAP[language.locale]} width="18px" height="18px" />
                  <Text m={0} fontSize="14px" lineHeight="20px" fontFamily="enz500">
                    {language.title}
                  </Text>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default LocaleMenuDesktop
