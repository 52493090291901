import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSiteSearch } from './SiteSearch'

const ROUTE_CHANGE_EVENT = 'routeChangeComplete'
const HASH_CHANGE_EVENT = 'hashChangeComplete'

const useSiteSearchDrawer = () => {
  const router = useRouter()

  const {
    isOpen: isSiteSearchOpen,
    onOpen: handleOpenSiteSearch,
    onClose: handleCloseSiteSearch,
  } = useSiteSearch()

  useEffect(() => {
    router.events.on(ROUTE_CHANGE_EVENT, handleCloseSiteSearch)
    router.events.on(HASH_CHANGE_EVENT, handleCloseSiteSearch)

    return () => {
      router.events.off(ROUTE_CHANGE_EVENT, handleCloseSiteSearch),
        router.events.off(HASH_CHANGE_EVENT, handleCloseSiteSearch)
    }
  }, [router.events, handleCloseSiteSearch, isSiteSearchOpen])

  return {
    isSiteSearchOpen,
    handleOpenSiteSearch,
    handleCloseSiteSearch,
  }
}

export { useSiteSearchDrawer }
