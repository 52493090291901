import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react'
import { CardWithLink_Swnz_CardWithLinkFragment, Maybe, Swnz_CardWithLink } from 'content-service'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, OptimisedImage } from 'ui'
import { createHref, IMAGE_SIZE_CONFIG } from 'utils'

const NavFeaturedStory = ({
  title,
  image,
  description,
  customUrl,
  link,
}: CardWithLink_Swnz_CardWithLinkFragment) => {
  const story = {
    title: title ?? '',
    description: description ?? '',
    image: OptimisedImage({
      src: image?.source?.url ?? '',
      alt: image?.source?.description ?? '',
      title: image?.source?.title ?? '',
      imageSizeConfig: IMAGE_SIZE_CONFIG.NAV_FEATURED_STORY,
      quality: 100,
    }),
    link: {
      href: createHref({
        href: customUrl,
        internalLink: link,
      }),
      isExternal: Boolean(customUrl) && !link,
      title: link?.title ?? '',
    },
  }

  return (
    <Link
      onClick={() => {
        createUtagLinkClickEvent({
          linkLabel: title ?? '',
          linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
        })
      }}
      {...story.link}
      tabIndex={1}
      textDecoration="none"
      color="white"
      _hover={{
        color: 'navHover',
      }}
    >
      <Flex gap={4} alignItems="center">
        <Box
          w="60px"
          h="60px"
          position="relative"
          sx={{
            '& img': {
              borderRadius: '8px',
            },
          }}
        >
          <AspectRatio ratio={1 / 1}>{story.image}</AspectRatio>
        </Box>
        <Flex flexDir="column">
          <Text fontFamily="enz700" fontSize="16px" lineHeight="28px" m={0}>
            {title}
          </Text>
          <Text fontFamily="enz400" fontSize="14px" lineHeight="20px" m={0}>
            {description}
          </Text>
        </Flex>
      </Flex>
    </Link>
  )
}

const NavFeaturedStories = ({ stories }: { stories: Maybe<Swnz_CardWithLink>[] }) => {
  return (
    <Flex flexDir="column" gap={4}>
      {stories.map((item) => {
        if (!item) return null

        return <NavFeaturedStory key={item.sys.id} {...item} />
      })}
    </Flex>
  )
}

export default NavFeaturedStories
export { NavFeaturedStory }
