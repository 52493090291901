import {
  GenericText_Swnz_GenericTextFragment,
  IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragment,
  KeepExploring_Swnz_KeepExploringFragment,
  ModalPageSectionItems_Swnz_PageModalSectionsItemFragment,
  StandardNaumaiPageSectionItems_Swnz_NaumaiPageStandardSectionsItemFragment,
  StandardPageSectionItems_Swnz_PageStandardSectionsItemFragment,
  Swnz_PageModalHero
} from 'content-service'

type PageModalSectionItems =
  | ModalPageSectionItems_Swnz_PageModalSectionsItemFragment
  | Swnz_PageModalHero

export type SectionCollectionId =
  | StandardPageSectionItems_Swnz_PageStandardSectionsItemFragment
  | StandardNaumaiPageSectionItems_Swnz_NaumaiPageStandardSectionsItemFragment
  | PageModalSectionItems
  | IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragment
  | GenericText_Swnz_GenericTextFragment
  | KeepExploring_Swnz_KeepExploringFragment
  | null
  | undefined

export type SvgrComponent = React.FunctionComponent<
  React.SVGAttributes<SVGElement>
>

export enum ImageLoadingPreference {
  EAGER = 'eager',
  LAZY = 'lazy'
}
