import { Text } from '@chakra-ui/react'
import { Swnz_NavigationSection, useGetSwnzNavigationElementsByCodeIdQuery } from 'content-service'
import { ReactElement } from 'react'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

interface NavProps {
  codeId: string
  preview?: boolean
  locale: string
}

const Nav = ({ codeId, preview = false, locale }: NavProps): ReactElement => {
  // The getSwnzNavigationElementsByCodeId query is hitting query limits as it's too complex.
  // This queries for each "section" of the main navigation one at a time
  const {
    loading: firstQueryLoading,
    error: firstQueryError,
    data: firstQueryData,
  } = useGetSwnzNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 0 },
  })
  const {
    loading: secondQueryLoading,
    error: secondQueryError,
    data: secondQueryData,
  } = useGetSwnzNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 1 },
  })
  const {
    loading: thirdQueryLoading,
    error: thirdQueryError,
    data: thirdQueryData,
  } = useGetSwnzNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 2 },
  })

  const error = firstQueryError || secondQueryError || thirdQueryError
  const loading = firstQueryLoading || secondQueryLoading || thirdQueryLoading

  if (error || loading)
    return (
      <Text padding={{ base: 0, lg: '30px' }} m={0}>
        {error ? 'Error' : 'Loading'}
      </Text>
    )

  const navigationItems = [
    ...(firstQueryData?.swnz_navigationCollection?.items?.[0]?.navigationElementsCollection
      ?.items ?? []),
    ...(secondQueryData?.swnz_navigationCollection?.items?.[0]?.navigationElementsCollection
      ?.items ?? []),
    ...(thirdQueryData?.swnz_navigationCollection?.items?.[0]?.navigationElementsCollection
      ?.items ?? []),
  ]

  if (!navigationItems || navigationItems.length === 0)
    return (
      <Text padding={{ base: 0, lg: '30px' }} m={0}>
        No Data
      </Text>
    )

  return (
    <>
      <MobileNav
        display={{ base: 'block', lg: 'none' }}
        links={navigationItems as Swnz_NavigationSection[]}
        preview={preview}
        locale={locale}
      />
      <DesktopNav
        display={{ base: 'none', lg: 'flex' }}
        links={navigationItems as Swnz_NavigationSection[]}
      />
    </>
  )
}

export default Nav
