import { Flex } from '@chakra-ui/react'
import { useGetNaumaiNavigationElementsByCodeIdQuery } from 'content-service'
import { useRouter } from 'next/router'
import { ReactElement, useState } from 'react'
import { createLinks } from 'utils/src/helpers/nav'
import { MainNav } from './main'
import { SecondaryNav } from './secondary'
interface NavProps {
  codeId: string
  preview?: boolean
}

const Nav = ({ codeId, preview = false }: NavProps): ReactElement => {
  const { locale } = useRouter()

  const [selectedItem, setSelection] = useState<string | null>(null)

  // The getNaumaiNavigationElementsByCodeIdQuery query is hitting query limits as it's too complex.
  // This queries for each "section" of the main navigation two at a time
  const {
    loading: firstQueryLoading,
    error: firstQueryError,
    data: firstQueryData
  } = useGetNaumaiNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 0 }
  })
  const {
    loading: secondQueryLoading,
    error: secondQueryError,
    data: secondQueryData
  } = useGetNaumaiNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 2 }
  })
  const {
    loading: thirdQueryLoading,
    error: thirdQueryError,
    data: thirdQueryData
  } = useGetNaumaiNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 4 }
  })
  const {
    loading: fourthQueryLoading,
    error: fourthQueryError,
    data: fourthQueryData
  } = useGetNaumaiNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale, skip: 6 }
  })

  const error =
    firstQueryError || secondQueryError || thirdQueryError || fourthQueryError

  const loading =
    firstQueryLoading ||
    secondQueryLoading ||
    thirdQueryLoading ||
    fourthQueryLoading

  if (error) return <p>Error</p>
  if (loading) return <p>Loading</p>

  const navigationItems = [
    ...(firstQueryData?.swnz_navigationCollection?.items?.[0]
      ?.navigationElementsCollection?.items ?? []),
    ...(secondQueryData?.swnz_navigationCollection?.items?.[0]
      ?.navigationElementsCollection?.items ?? []),
    ...(thirdQueryData?.swnz_navigationCollection?.items?.[0]
      ?.navigationElementsCollection?.items ?? []),
    ...(fourthQueryData?.swnz_navigationCollection?.items?.[0]
      ?.navigationElementsCollection?.items ?? [])
  ]

  if (!navigationItems || navigationItems.length === 0) {
    return <p>No Data</p>
  }

  const links = createLinks({
    linkItems: { items: navigationItems }
  })

  const callback = (id?: string) => {
    if (!id) return

    setSelection(id)
  }

  return (
    <Flex as='nav' flexDir='column'>
      {selectedItem
        ? links && (
            <SecondaryNav
              links={links}
              selected={selectedItem}
              navigateToMainNav={() => setSelection(null)}
            />
          )
        : links && <MainNav links={links} callback={callback} />}
    </Flex>
  )
}

export default Nav
