import ALGOLIA_INDICES from './algolia'
import ALGOLIA_FILTER_MAPS from './algoliaFilterMaps'
import { Actions, Statuses } from './apiReducer'
import AUTH from './auth'
import CLAIM from './claims'
import CONTENT_SERVICE from './contentService'
import COOKIE from './cookie'
import DEFAULT_SEO from './defaultSeo'
import { PopularSearchType } from './popularSearchItems'
import ROUTES from './routes'
import SD_SERVICES from './sdServices'
import SITE_CONFIG from './siteConfig'
import SLUG, { SEARCH_RESULTS_PAGE_SLUG_MAP, SUPRESS_POPUP_SLUGS } from './slug'
import SUPPORTED_LANGUAGES, { SupportedLanguage } from './supportedLanguages'

export {
  Actions,
  ALGOLIA_FILTER_MAPS,
  ALGOLIA_INDICES,
  AUTH,
  CLAIM,
  CONTENT_SERVICE,
  COOKIE,
  DEFAULT_SEO,
  ROUTES,
  SD_SERVICES,
  SEARCH_RESULTS_PAGE_SLUG_MAP,
  SITE_CONFIG,
  SLUG,
  Statuses,
  SUPPORTED_LANGUAGES,
  SUPRESS_POPUP_SLUGS,
}
export type { PopularSearchType, SupportedLanguage }
