import { Box, ButtonProps, Flex } from '@chakra-ui/react'
import { ChevronCircleLarge } from 'icons'

const BackButton = ({ onClick, ...props }: ButtonProps) => {
  return (
    <Box
      as="button"
      width="100%"
      onClick={onClick}
      textDecoration="none"
      color="white"
      _hover={{
        color: 'navHover',
      }}
      transition="all"
      transitionDuration="200ms"
      {...props}
    >
      <Flex alignItems="center" py="16px" gap="8px">
        <Box
          h="32px"
          w="32px"
          sx={{
            svg: {
              transform: 'scaleX(-1)',
            },
            'html[lang=ar] &': {
              transform: ['rotate(180deg)'],
            },
          }}
        >
          <ChevronCircleLarge />
        </Box>
        <Box as="span" fontFamily="enz500" fontSize="14px" lineHeight="20px">
          Back to main menu
        </Box>
      </Flex>
    </Box>
  )
}

export default BackButton
