import { useAuth0 } from '@auth0/auth0-react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import { UserIcon } from 'icons'
import Cookies from 'js-cookie'
import { useUser } from 'src/context'
import { useTranslation } from 'src/hooks/useTranslation'
import { CONTENT_SERVICE, COOKIE, ROUTES } from 'swnz/src/constants'
import useLogin from 'swnz/src/hooks/useLogin'
import NavLinks from './NavLinks'
import ProfileNavLinks from './ProfileNavLinks'

const ProfileMenuMobile = ({ preview, locale }: { preview: boolean; locale: string }) => {
  const { isAuthenticated, isLoading } = useAuth0()
  const { userState } = useUser()
  const login = useLogin()
  const { t } = useTranslation('navigation')

  function setEmailVerificationCookie() {
    if (typeof window === 'undefined') {
      return
    }

    // Don't allow users to be redirected to the email unverified page after they have
    // been redirected. Default to the homepage
    const redirectLocation =
      window?.location?.pathname === ROUTES.AUTH.EMAIL_UNVERIFIED
        ? ROUTES.HOME
        : window.location.pathname

    Cookies.set(COOKIE.LOGIN_REDIRECT, redirectLocation, { sameSite: 'lax' })
  }

  const isSignedIn = isAuthenticated && !isLoading

  return (
    <>
      <Accordion allowToggle width="100%">
        <AccordionItem mb={0} border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                tabIndex={1}
                _hover={{ color: 'navHover', backgroundColor: 'initial' }}
                px={0}
                py="6px"
              >
                <Flex align="center" gap="6px">
                  <UserIcon width="18px" height="18px" />
                  <Text
                    minW="max-content"
                    m={0}
                    fontSize="14px"
                    lineHeight="20px"
                    fontFamily="enz500"
                  >
                    {isSignedIn ? userState.profile?.firstName ?? t('welcome') : t('sign_in')}
                  </Text>
                  <Icon
                    as={ChevronDownIcon}
                    transition="transform 0.2s"
                    transform={isExpanded ? 'rotate(180deg)' : ''}
                    color={isExpanded ? 'surfaceBlue' : ''}
                    width="18px"
                    height="18px"
                  />
                </Flex>
              </AccordionButton>
              <AccordionPanel
                mt="4px"
                bg="deepblue.80"
                borderRadius="8px"
                p="16px"
                boxShadow="0px 4px 16px 0 rgba(0,0,0,0.2)"
                display="flex"
                flexDir="column"
                gap="8px"
              >
                {isSignedIn ? (
                  <ProfileNavLinks />
                ) : (
                  <>
                    <Box
                      width="100%"
                      as="button"
                      tabIndex={1}
                      onClick={() => {
                        setEmailVerificationCookie()
                        login('login')
                      }}
                      display="flex"
                      color="white"
                      _hover={{ color: 'navHover' }}
                      textDecor="none"
                      alignItems="center"
                      fontFamily="enz500"
                      fontSize="14px"
                      lineHeight="20px"
                      py="6px"
                    >
                      {t('sign_in')}
                    </Box>
                    <Box
                      width="100%"
                      as="button"
                      tabIndex={1}
                      onClick={() => {
                        setEmailVerificationCookie()
                        login('signup')
                      }}
                      display="flex"
                      color="white"
                      _hover={{ color: 'navHover' }}
                      textDecor="none"
                      alignItems="center"
                      fontFamily="enz500"
                      fontSize="14px"
                      lineHeight="20px"
                      py="6px"
                    >
                      {t('create_an_account')}
                    </Box>
                    <Box as="hr" background="white" opacity={0.24} my={0} />
                    <NavLinks
                      locale={locale}
                      preview={preview}
                      codeId={CONTENT_SERVICE.SECONDARY_TOP_NAV_LOGGED_IN}
                      flexDir="column"
                    />
                  </>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default ProfileMenuMobile
