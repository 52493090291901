import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import { useGetTopNavigationElementsByCodeIdQuery } from 'content-service'
import { ExternalLinkIcon } from 'icons'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, NavLink } from 'ui'
import { CreateLinks, createLinks } from 'utils/src/helpers/nav'

const NavLinks = ({
  preview,
  locale,
  codeId,
  ...props
}: {
  preview: boolean
  locale: string
  codeId: string
} & FlexProps) => {
  const { data } = useGetTopNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale },
  })

  const links: Array<NavLink> =
    createLinks({
      linkItems: data?.swnz_navigationCollection?.items[0]?.navigationElementsCollection,
    } as CreateLinks) ?? []

  if (!links || links.length === 0) return null

  return (
    <Flex as="ul" flexDir={{ base: 'column', lg: 'row' }} gap={6} {...props}>
      {links.map((link) => (
        <Box as="li" listStyleType="none" key={link.id}>
          <Link
            tabIndex={1}
            color="white"
            _hover={{
              color: 'navHover',
            }}
            textDecor="none"
            fontSize="14px"
            py="6px"
            fontFamily="enz500"
            lineHeight="20px"
            href={link.slug}
            isExternal={link.isExternalLink}
            onClick={() =>
              createUtagLinkClickEvent({
                linkLabel: link.title,
                linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
              })
            }
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Text m={0}>{link.title}</Text>
            {link.openLinkInNewWindow === true && (
              <Box boxSize="18px">
                <ExternalLinkIcon />
              </Box>
            )}
          </Link>
        </Box>
      ))}
    </Flex>
  )
}

export default NavLinks
