import { NavLink } from '../../Nav/NavItem'
import { BreadcrumbNavBackButtonProps } from '../BreadcrumbNavBackButton'
import { BreadcrumbNavLinksProps } from '../BreadcrumbNavLinks'

const createBreadcrumbNavLinksProps = (
  pathArray: string[] = [],
  breadcrumbNavLinks: NavLink
): BreadcrumbNavLinksProps => {
  const { children = [] } = breadcrumbNavLinks || {}

  return {
    currentPath: `/${pathArray.join('/')}`,
    links: children
  }
}

const createBreadcrumbBackButtonProps = (
  hasParentPageLink: boolean,
  breadcrumbNavLinks: NavLink,
  destination: string
): BreadcrumbNavBackButtonProps => ({
  title: hasParentPageLink ? breadcrumbNavLinks.title : destination,
  slug: hasParentPageLink ? breadcrumbNavLinks.slug : '/'
})

export { createBreadcrumbBackButtonProps, createBreadcrumbNavLinksProps }
