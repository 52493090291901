import { Box, Flex, GridItem, Text } from '@chakra-ui/react'
import {
  NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
  useGetNavigationElementsForFooterQuery,
} from 'content-service'
import { VerticalThinkNewNZLogo } from 'icons'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { isPresent } from 'ts-is-present'
import {
  ContainerWrapper,
  FooterSectionLegalLinks,
  FooterSectionLinks,
  FooterSectionSocialLinks,
  GridLayout,
  Image,
  Link,
} from 'ui'
import { createLinks } from 'utils/src/helpers/nav'
interface CreateFooterSection {
  title: string | undefined
  links: Pick<
    NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
    'items'
  > | null
}

interface FooterProps {
  preview?: boolean
}

const createFooterSection = ({ title = '', links }: CreateFooterSection) => {
  return {
    title,
    links: createLinks({
      linkItems: links,
    }),
  }
}

const Footer = ({ preview = false }: FooterProps): ReactElement | null => {
  const { locale } = useRouter()

  const { data } = useGetNavigationElementsForFooterQuery({
    variables: {
      preview,
      locale,
    },
  })
  const { footer, socialLinks, legal } = data || {}

  const footerSectionLinks =
    footer?.items[0]?.navigationElementsCollection?.items
      .map((item) => {
        if (item?.__typename !== 'SWNZ_NavigationElement') return
        return createFooterSection({
          title: item?.title ?? '',
          links: item?.navigationElementsCollection ?? null,
        })
      })
      .filter(isPresent) ?? []

  const socialNavElement = socialLinks?.items[0]?.navigationElementsCollection?.items[0]
  const footerNavElement = footer?.items[0]?.navigationElementsCollection?.items[0]

  const socialLinksSection = createFooterSection({
    title:
      socialNavElement?.__typename === 'SWNZ_NavigationElement' && socialNavElement?.title
        ? socialNavElement.title
        : '',
    links:
      socialNavElement?.__typename === 'SWNZ_NavigationElement' &&
      socialNavElement?.navigationElementsCollection
        ? socialNavElement.navigationElementsCollection
        : null,
  })

  const legalSection = createFooterSection({
    title: '',
    links: legal?.items[0]?.navigationElementsCollection ?? null,
  } as CreateFooterSection)

  const currentYear = `${new Date().getFullYear()}`

  return (
    <Box
      as="footer"
      bg="deepblue.500"
      paddingTop={{ base: 14, lg: 16 }}
      paddingBottom={{ base: 32, lg: 16 }}
      dir="ltr"
    >
      <ContainerWrapper sx={{ py: 0 }}>
        <GridLayout m={0} templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(12, 1fr)' }}>
          <GridItem colSpan={{ base: 12, lg: 3, xl: 2 }}>
            <Link href="/" title="Link to homepage" aria-label="Link to homepage" display="block">
              <Box
                color="white"
                w={{ base: '112px', xs: '164px' }}
                h={{ base: '44px', xs: '64px' }}
                mb={8}
              >
                <VerticalThinkNewNZLogo />
              </Box>
            </Link>
          </GridItem>

          {footerSectionLinks.map((section, i) => (
            <GridItem key={i} colSpan={{ base: 12, lg: 3, xl: 2 }}>
              <FooterSectionLinks title={section.title} links={section.links} />
            </GridItem>
          ))}

          <GridItem
            colSpan={{ base: 12, lg: 3, xl: 2 }}
            colStart={{ xl: 11 }}
            borderTop={{ base: '1px solid rgba(255,255,255,0.1)', lg: 'none' }}
            sx={{
              // actually rtl
              'html[lang=ar] &': {
                direction: 'ltr',
              },
            }}
          >
            <FooterSectionSocialLinks
              title={socialLinksSection.title}
              links={socialLinksSection.links}
            />
          </GridItem>
        </GridLayout>
        <Flex justifyContent={{ lg: 'flex-end' }} my={{ base: 8, lg: 12 }}>
          <Link
            href="https://www.govt.nz/"
            title="Link to New Zealand Government"
            aria-label="Link to New Zealand Government"
            display="block"
            isExternal
          >
            <Image
              src="/nzgovt-logo.png"
              alt="New Zealand Government"
              width="250"
              height="56"
              blurOnLoad={false}
            />
          </Link>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
        >
          <Text color="white" fontSize="sm" mb={{ base: 2, lg: 0 }}>
            All content &copy; Education New Zealand {currentYear}
          </Text>

          <FooterSectionLegalLinks links={legalSection.links} />
        </Flex>
      </ContainerWrapper>
    </Box>
  )
}

export default Footer
