import { Box, BoxProps } from '@chakra-ui/react'
import { SearchIcon } from 'icons'

interface SearchInputIconProps extends BoxProps {
  searchButtonSize?: string
}

const SearchInputIcon = ({
  searchButtonSize,
  boxSize = { base: 8, lg: 9 },
  sx,
  ...props
}: SearchInputIconProps) => {
  return (
    <Box
      display='flex'
      position='absolute'
      right={0}
      width={searchButtonSize}
      height={searchButtonSize}
      minWidth={searchButtonSize}
      justifyContent='center'
      alignItems='center'
      sx={{
        'html[lang=ar] &': {
          left: 0,
          right: 'unset'
        },
        ...sx
      }}
      {...props}
    >
      <Box boxSize={boxSize}>
        <SearchIcon />
      </Box>
    </Box>
  )
}

export default SearchInputIcon
