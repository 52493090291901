const colors = {
  transparent: 'transparent',
  grey: {
    5: '#F7F7F7',
    10: '#EEEFF0',
    100: '#D7D7D7',
    200: '#9ca3af',
    300: '#757575',
    400: '#4d4d4d',
    500: '#6A7587',
    600: '#4C5662'
  },
  deepblue: {
    5: '#F5F5F6',
    10: '#F8F8F9',
    50: '#F3F4F5',
    80: '#39475F',
    100: '#E6E8EB',
    200: '#CDD1D7',
    300: '#6A7587',
    400: '#EEF4F4',
    450: '#25354F',
    350: '#07193780',
    500: '#071937',
    600: '#040F20'
  },
  red: {
    50: '#FFF4F6',
    400: '#DF3550',
    600: '#AB0000'
  },
  orange: {
    10: '#FCF9F6',
    50: '#FCEEEA',
    500: '#DF532D',
    600: '#D24620'
  },
  green: {
    300: '#008A60',
    400: '#6EC248',
    500: '#2fa84f'
  },
  teal: {
    5: '#EDF8F8',
    10: '#E9F6F6',
    100: '#EEF4F4',
    200: '#DDEAEA',
    300: '#90D0D0',
    500: '#22a2a2',
    600: '#209898',
    700: '#1E8D8D'
  },
  sand: {
    20: '#E0C7A733',
    30: '#F5EEE5',
    100: '#EFE2D2'
  }
}
const aliases = {
  navHover: colors.grey[200],
  harakekeGreen: colors.green[300],
  surfaceBlue: colors.teal[500],
  deepWaterBlue: colors.deepblue[600],
  eastCoastSand: colors.sand[100],
  manapouOrange: colors.orange[500],
  westCoastSand: colors.grey[600],
  primary: colors.teal[500],
  secondary: colors.deepblue[600],
  tertiary: colors.orange[500],
  success: colors.green[400],
  error: colors.orange[500]
}
export default {
  ...colors,
  ...aliases
}
