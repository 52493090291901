import { Box, Flex, Text } from '@chakra-ui/react'
import { CtaBanner_Swnz_BannerFragment } from 'content-service'
import { useRouter } from 'next/router'
import { CtaButton, RichTextRenderer } from 'ui'
import { createCtaButton } from 'utils'

const NavCtaBanner = ({
  title,
  bodyText,
  primaryCta,
  secondaryCta,
}: CtaBanner_Swnz_BannerFragment) => {
  const router = useRouter()
  const pathname = router.asPath ?? ''

  const primaryCtaProps = primaryCta
    ? createCtaButton({
        ...primaryCta,
        href:
          primaryCta.href && primaryCta.href.startsWith('?')
            ? `${pathname}${primaryCta.href}`
            : primaryCta.href,
      })
    : undefined
  const secondaryCtaProps = secondaryCta
    ? createCtaButton({
        ...secondaryCta,
        href:
          secondaryCta.href && secondaryCta.href.startsWith('?')
            ? `${pathname}${secondaryCta.href}`
            : secondaryCta.href,
      })
    : undefined

  return (
    <Flex
      flexDir="column"
      backgroundColor="rgba(255,255,255,0.2)"
      gap={4}
      borderRadius="8px"
      p={{ base: 4, lg: 6 }}
    >
      <Flex flexDir="column" gap={2}>
        <Text fontFamily="enz700" fontSize="16px" lineHeight="28px" color="white" m={0}>
          {title}
        </Text>
        <Box opacity={0.75}>{bodyText && <RichTextRenderer richText={bodyText} />}</Box>
      </Flex>
      <Flex flexWrap="wrap" gap={4}>
        {primaryCtaProps && (
          <CtaButton
            {...primaryCtaProps}
            size="sm"
            w="fit-content"
            tabIndex={1}
            externalIconSize="20px"
          >
            {primaryCtaProps.label}
          </CtaButton>
        )}
        {secondaryCtaProps && (
          <CtaButton
            {...secondaryCtaProps}
            size="sm"
            w="fit-content"
            tabIndex={1}
            externalIconSize="20px"
          >
            {secondaryCtaProps.label}
          </CtaButton>
        )}
      </Flex>
    </Flex>
  )
}

export default NavCtaBanner
