import {
  StoryCardContainer_Swnz_StoryCardContainerFragment,
  StoryCardContainer_Swnz_StoryCardContainerFragmentDoc
} from 'content-service'
import { ReactElement } from 'react'
import { CardCarouselGallery } from 'ui'
import { colourMap, IMAGE_SIZE_CONFIG } from 'utils'
import useFragment from 'utils/src/hooks/useFragment'
import { OptimisedImage } from '../../components'
import { Connector } from '../types'

export interface StoryCardContainerConnectProps extends Connector {
  pageTheme?: string
}

const StoryCardContainerConnect = ({
  id,
  typename,
  pageTheme
}: StoryCardContainerConnectProps): ReactElement | null => {
  const storyCardContainer =
    useFragment<StoryCardContainer_Swnz_StoryCardContainerFragment>({
      id,
      typename,
      fragment: StoryCardContainer_Swnz_StoryCardContainerFragmentDoc,
      fragmentName: 'storyCardContainer_SWNZ_StoryCardContainer'
    })

  if (!storyCardContainer) {
    return null
  }

  const storyCardContainerItems =
    storyCardContainer?.storyCardsCollection?.items
  const storyCards = storyCardContainerItems
    ? storyCardContainerItems.map((item) => {
        return {
          name: item?.name || '',
          areaOfStudy: item?.areaOfStudy || '',
          location: item?.location || '',
          image: OptimisedImage({
            src: item?.image?.source?.url || '',
            alt: item?.image?.source?.description || '',
            title: item?.image?.source?.title || '',
            imageSizeConfig: IMAGE_SIZE_CONFIG.CARD_CAROUSEL_GALLERY_CARD
          }),
          modalId: item?.modalContent?.sys.id || '',
          modalHasHeroVideo:
            item?.modalContent?.hero?.__typename === 'SWNZ_MediaVideo',
          tags: item?.tagRefCollection?.items ?? []
        }
      })
    : []

  let bgColour = storyCardContainer.backgroundColour
    ? colourMap(storyCardContainer.backgroundColour)
    : 'deep-water-blue'
  let textColor = 'white'
  const pseudoColor = 'deep-water-blue'

  if (pageTheme === 'campaign-garb') {
    bgColour = 'sand.100'
    textColor = 'deep-water-blue'
  }

  return (
    <CardCarouselGallery
      title={storyCardContainer.title ?? ''}
      backgroundColor={bgColour}
      pseudoColor={pseudoColor}
      textColor={textColor}
      subHeading={storyCardContainer.subHeading ?? ''}
      cards={storyCards}
    />
  )
}

export default StoryCardContainerConnect
