import { Box, Flex, Text } from '@chakra-ui/react'
import {
  Swnz_NavigationElement,
  useGetNavigationElementsForBreadcrumbsQuery,
} from 'content-service'
import { ChevronCircleLarge } from 'icons'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { createBreadcrumbNavBasePath, Link, NavLink } from 'ui'
import { createBreadcrumbBackButtonProps } from 'ui/src/components/BreadcrumbNav/helpers'
import { cnNavFilter } from 'utils/src/helpers/cnNavFilter'
import { createLinks } from 'utils/src/helpers/nav'

const BreadcrumbNav = ({
  pathArray,
  preview = false,
}: {
  pathArray: string[]
  preview?: boolean
}) => {
  const sectionCodeId = createBreadcrumbNavBasePath(pathArray)
  const router = useRouter()
  const { data } = useGetNavigationElementsForBreadcrumbsQuery({
    variables: { codeId: sectionCodeId, preview, locale: router.locale },
  })

  const { t } = useTranslation('navigation')

  if (
    !data ||
    !data.swnz_navigationElementCollection ||
    !data.swnz_navigationElementCollection.items ||
    data.swnz_navigationElementCollection.items.length === 0
  )
    return null

  let navigationElements = data.swnz_navigationElementCollection

  if (router.locale === 'cn') {
    navigationElements = {
      ...data.swnz_navigationElementCollection,
      items: cnNavFilter(
        data.swnz_navigationElementCollection.items as Array<Swnz_NavigationElement>
      ),
    }
  }

  const links: NavLink[] = createLinks({
    linkItems: navigationElements,
  })

  const hasParentPageLink = Boolean(
    data?.swnz_navigationElementCollection?.items[0]?.internalPageLink
  )
  const [breadcrumbNavLinks] = links

  const destination = t('breadcrumb_nav_return_destination')

  const breadcrumbBackButtonProps = createBreadcrumbBackButtonProps(
    hasParentPageLink,
    breadcrumbNavLinks,
    destination
  )

  return (
    <Box
      maxW={{ base: 'container.xl', xxl: 'none' }}
      m="auto"
      backgroundColor="deepblue.500"
      color="white"
      paddingInlineStart={{ base: 4, lg: 16 }}
      borderTop="1px solid rgba(255,255,255,0.24)"
    >
      <Flex justifyContent="space-between" alignItems="center" gap={5}>
        <Link
          onClick={() => {
            createUtagLinkClickEvent({
              linkLabel: breadcrumbBackButtonProps.title,
              linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
            })
          }}
          tabIndex={1}
          display="flex"
          alignItems="center"
          textDecoration="none"
          color="white"
          _hover={{
            color: 'navHover',
          }}
          href={breadcrumbBackButtonProps.slug}
        >
          <Box
            py={4}
            paddingInlineEnd={4}
            borderInlineEnd={{ base: '1px solid rgba(255,255,255,0.24)', lg: 'none' }}
          >
            <Box
              w="32px"
              h="32px"
              transform="rotate(180deg)"
              sx={{
                'html[lang=ar] &': {
                  transform: ['rotate(0deg)'],
                },
              }}
            >
              <ChevronCircleLarge />
            </Box>
          </Box>
          <Box display={{ base: 'none', lg: 'block' }}>
            <Text as="span" display="block" m="0" lineHeight="normal" fontSize="12px">
              {t('breadcrumb_nav_return_action')}
            </Text>
            <Text
              as="span"
              display="block"
              m="0"
              lineHeight="normal"
              fontFamily="enz500"
              fontSize="16px"
            >
              {breadcrumbBackButtonProps.title}
            </Text>
          </Box>
        </Link>

        {breadcrumbNavLinks &&
        breadcrumbNavLinks.children &&
        breadcrumbNavLinks.children.length > 0 ? (
          <Flex as="nav" role="breadcrumb-navigation" overflow="hidden">
            <Flex
              as="ul"
              listStyleType="none"
              gap={{ base: 4, lg: 7 }}
              overflowX="auto"
              paddingInlineEnd={{ base: 4, lg: 16 }}
              paddingY={4}
            >
              {breadcrumbNavLinks.children.map(({ slug, title, id, isExternalLink, ...link }) => (
                <Box key={id} as="li" whiteSpace="nowrap">
                  <Link
                    onClick={() => {
                      createUtagLinkClickEvent({
                        linkLabel: title,
                        linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                      })
                    }}
                    tabIndex={1}
                    href={slug}
                    isExternal={isExternalLink}
                    display="block"
                    textDecoration="none"
                    color="white"
                    _hover={{
                      color: 'navHover',
                    }}
                    fontFamily="enz700"
                    fontSize="16px"
                    lineHeight="28px"
                    {...link}
                  >
                    <Box
                      as="span"
                      display="block"
                      borderBottom={slug === router.asPath ? '2px solid' : 'none'}
                      borderColor="surfaceBlue"
                    >
                      {title}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  )
}

export default BreadcrumbNav
