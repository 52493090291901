import { MediaImage_Swnz_MediaImageFragment } from 'content-service'
import { CardCarouselGallery } from '../content-components'
import { CarouselType } from '../content-components/CardCarouselGallery/CardCarouselGallery'
import { OptimisedImage } from '../Image'

interface StatsImageCarouselProps {
  title?: string | null
  images: MediaImage_Swnz_MediaImageFragment[]
}

const StatsImageCarousel = ({ title, images }: StatsImageCarouselProps) => {
  const cards =
    images.map((image) => ({
      image: OptimisedImage({
        src: image?.source?.url || '',
        alt: image.source?.description || '',
        title: image?.source?.title || ''
      })
    })) ?? []

  return (
    <CardCarouselGallery
      title={title ?? ''}
      backgroundColor='teal.500'
      textColor='white'
      pseudoColor='deep-water-blue'
      cards={cards}
      carouselType={CarouselType.StatsImageCarousel}
    />
  )
}

export default StatsImageCarousel
