import { useAuth0 } from '@auth0/auth0-react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Flex, Icon, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react'
import { UserIcon } from 'icons'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { useUser } from 'src/context'
import { useTranslation } from 'src/hooks/useTranslation'
import { CONTENT_SERVICE, COOKIE, ROUTES } from 'swnz/src/constants'
import useLogin from 'swnz/src/hooks/useLogin'
import NavLinks from './NavLinks'
import ProfileNavLinks from './ProfileNavLinks'

const ProfileMenuDesktop = ({ preview, locale }: { preview: boolean; locale: string }) => {
  const { isAuthenticated, isLoading } = useAuth0()
  const { userState } = useUser()
  const { t } = useTranslation('navigation')
  const login = useLogin()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function setEmailVerificationCookie() {
    if (typeof window === 'undefined') {
      return
    }

    // Don't allow users to be redirected to the email unverified page after they have
    // been redirected. Default to the homepage
    const redirectLocation =
      window?.location?.pathname === ROUTES.AUTH.EMAIL_UNVERIFIED
        ? ROUTES.HOME
        : window.location.pathname

    Cookies.set(COOKIE.LOGIN_REDIRECT, redirectLocation, { sameSite: 'lax' })
  }

  const isSignedIn = isAuthenticated && !isLoading

  const handleOnOpen = () => {
    setIsOpen(true)
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Menu onOpen={handleOnOpen} onClose={handleOnClose} isOpen={isOpen}>
        <MenuButton color="white" _hover={{ color: 'navHover' }} tabIndex={1} py="6px">
          <Flex align="center" gap="6px">
            <UserIcon width="18px" height="18px" />
            <Text minW="max-content" m={0} fontSize="14px" lineHeight="20px" fontFamily="enz500">
              {isSignedIn ? userState.profile?.firstName ?? t('welcome') : t('sign_in')}
            </Text>
            <Icon
              as={ChevronDownIcon}
              transition="transform 0.2s"
              transform={isOpen ? 'rotate(180deg)' : ''}
              color={isOpen ? 'surfaceBlue' : ''}
              width="18px"
              height="18px"
            />
          </Flex>
        </MenuButton>
        <MenuList
          display="flex"
          flexDir="column"
          gap={2}
          w="fit-content"
          minW="200px"
          p={4}
          bg="deepblue.80"
          border="none"
          mt="-6px"
        >
          {isSignedIn ? (
            <ProfileNavLinks callback={handleOnClose} />
          ) : (
            <>
              <Box
                width="100%"
                as="button"
                tabIndex={1}
                onClick={() => {
                  setEmailVerificationCookie()
                  login('login')
                }}
                display="flex"
                color="white"
                _hover={{ color: 'navHover' }}
                textDecor="none"
                alignItems="center"
                fontFamily="enz500"
                fontSize="14px"
                lineHeight="20px"
                py="6px"
              >
                {t('sign_in')}
              </Box>
              <Box
                width="100%"
                as="button"
                tabIndex={1}
                onClick={() => {
                  setEmailVerificationCookie()
                  login('signup')
                }}
                display="flex"
                color="white"
                _hover={{ color: 'navHover' }}
                textDecor="none"
                alignItems="center"
                fontFamily="enz500"
                fontSize="14px"
                lineHeight="20px"
                py="6px"
              >
                {t('create_an_account')}
              </Box>
              <Box as="hr" background="white" opacity={0.24} m={0} />
              <NavLinks
                preview={preview}
                locale={locale}
                codeId={CONTENT_SERVICE.SECONDARY_TOP_NAV_LOGGED_IN}
                flexDir="column"
              />
            </>
          )}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default ProfileMenuDesktop
