import { Box, Heading, VStack } from '@chakra-ui/react'
import { LargeText } from 'ui'

interface CardCarouselHeading {
  title?: string
  subHeading?: string
}

const CardCarouselHeading = ({ title, subHeading }: CardCarouselHeading) => {
  return (
    <Box>
      <VStack marginBottom={6} marginX={4} textAlign='center'>
        {title && (
          <Heading
            color='white'
            marginBottom={{ base: 8, lg: 10 }}
            fontSize={{ base: '36px', lg: 'xxx-large' }}
          >
            {title}
          </Heading>
        )}
        {subHeading && (
          <LargeText as='h5' color='white'>
            {subHeading}
          </LargeText>
        )}
      </VStack>
    </Box>
  )
}

export default CardCarouselHeading
