import { AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js'
import { SearchClient } from 'algoliasearch/lite'
import { ROUTES } from 'swnz/src/constants'
import Highlight from '../../Highlight'
import { pluginOnSelect } from '../helpers'
import { addItemType } from '../helpers/addItemType'
import { SearchQueryResult, SearchType } from '../types'

interface CreateProviderDetailQuery {
  indexName: string
  searchClient: SearchClient
  filters: string
}

export function createProviderDetailPlugin({
  searchClient,
  indexName,
  filters = ''
}: CreateProviderDetailQuery): AutocompletePlugin<
  SearchQueryResult,
  SearchQueryResult
> {
  return {
    getSources({ query }) {
      if (!query) {
        return []
      }

      return [
        {
          sourceId: 'search',
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName,
                  query,
                  params: {
                    filters
                  }
                }
              ],
              transformResponse({ hits }) {
                // Set label on response
                const results = hits.flat().map((hit) => ({
                  ...hit,
                  label: hit?.name ?? '',
                  _highlightResult: {
                    ...hit._highlightResult,
                    label: {
                      value: hit._highlightResult.name?.value
                    }
                  }
                }))

                return [
                  addItemType<SearchQueryResult>({
                    type: SearchType.QUERY,
                    items: results
                  })
                ]
              }
            })
          },
          getItemUrl({ item }) {
            return `${ROUTES.PROVIDER.DETAIL}/${item.id}`
          },
          getItemInputValue({ item }) {
            return item?.label ?? ''
          },
          onSelect(params) {
            pluginOnSelect(params)
          },
          templates: {
            header() {
              return 'Suggested searches'
            },
            item({ item }) {
              return <Highlight attribute='name' hit={item} />
            }
          }
        }
      ]
    }
  }
}
