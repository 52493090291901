import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useOutsideClick,
} from '@chakra-ui/react'
import { Swnz_NavigationSection } from 'content-service'
import { ChevronCircleLarge } from 'icons'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { createLinks } from 'utils/src/helpers/nav'
import findIndexWithActiveLink from './helpers/findIndexWithActiveLink'
import NavAccordion from './NavAccordion'
import NavCtaBanner from './NavCtaBanner'
import NavFeaturedStories from './NavFeaturedStories'
import NavIntroduction from './NavIntroduction'
import NavQuickLinks from './NavQuickLinks'

const DesktopNav = ({
  links,
  ...props
}: FlexProps & {
  links: Swnz_NavigationSection[]
}) => {
  const { isAuthenticated } = useAuth0()
  const router = useRouter()
  const currentPath = router.asPath
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null)
  const openPopover = (id: string) => setOpenPopoverId(id)
  const closePopover = () => setOpenPopoverId(null)
  const popoverRef = useRef(null)

  useOutsideClick({
    ref: popoverRef,
    handler: closePopover,
  })

  useEffect(() => {
    const handleClosePopover = () => {
      if (openPopoverId !== null) {
        closePopover()
      }
    }
    router.events.on('routeChangeComplete', handleClosePopover)

    return () => {
      router.events.off('routeChangeComplete', handleClosePopover)
    }
  }, [router.events, openPopoverId])

  return (
    <Flex as="nav" {...props}>
      <Flex as="ul" gap="32px" ref={popoverRef}>
        {links.map((item) => {
          if (!item) return null

          const itemLinks = createLinks({
            linkItems: { items: item?.navigationElementsCollection?.items ?? [] },
          })
          const itemHasActiveLink =
            findIndexWithActiveLink(itemLinks, currentPath, { checkTopLevelLink: true }) !==
            undefined

          return (
            <Box key={item.sys.id} as="li" style={{ listStyleType: 'none' }}>
              <Popover
                trigger="click"
                placement="bottom"
                autoFocus
                gutter={0}
                isOpen={openPopoverId === item.sys.id}
                onClose={closePopover}
                closeOnBlur
              >
                {({ isOpen }) => (
                  <>
                    <PopoverTrigger>
                      <Flex
                        onClick={() => {
                          openPopover(item.sys.id)
                          createUtagLinkClickEvent({
                            linkLabel: item.title ?? '',
                            linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV,
                          })
                        }}
                        as="button"
                        tabIndex={1}
                        width="100%"
                        alignItems="center"
                        gap={2}
                        py="30px"
                        transition="all"
                        transitionDuration="200ms"
                        cursor="pointer"
                        color="white"
                        _hover={{
                          color: 'navHover',
                          '& path': {
                            fill: 'navHover',
                          },
                          '& circle': {
                            stroke: 'navHover',
                            fill: 'deepblue.500',
                          },
                        }}
                      >
                        <Box
                          as="span"
                          fontFamily="enz700"
                          fontSize="16px"
                          lineHeight="28px"
                          borderBottom={itemHasActiveLink ? 'solid 2px' : 'none'}
                          borderColor="surfaceBlue"
                        >
                          {item.title}
                        </Box>
                        <Box
                          h="24px"
                          w="24px"
                          transition="all"
                          transitionDuration="200ms"
                          transform={`rotate(${isOpen ? '-' : ''}90deg)`}
                          sx={{
                            path: {
                              fill: isOpen ? 'deepblue.500' : 'white',
                            },
                            circle: {
                              stroke: isOpen ? 'surfaceBlue' : 'white',
                              fill: isOpen ? 'surfaceBlue' : 'deepblue.500',
                            },
                          }}
                        >
                          <ChevronCircleLarge />
                        </Box>
                      </Flex>
                    </PopoverTrigger>
                    <PopoverContent
                      minW="100vw"
                      p="60px"
                      bg="deepblue.500"
                      borderRadius="none"
                      border={0}
                      variants={{
                        enter: {
                          opacity: 1,
                          translateY: 0,
                          transition: {
                            duration: 0.2,
                            ease: 'easeOut',
                            delay: 0.2,
                          },
                        },
                        exit: {
                          opacity: 0,
                          translateY: '-10px',
                          transition: {
                            duration: 0.2,
                            ease: 'easeIn',
                          },
                        },
                      }}
                    >
                      <Grid gap="48px" gridTemplateColumns="repeat(3, 1fr)">
                        {item.title ||
                        item.sectionDescription ||
                        (item.quickLinksTitle &&
                          item.quickLinksCollection &&
                          item.quickLinksCollection.items.length > 0) ? (
                          <GridItem display="flex" flexDir="column" gap={10}>
                            {(item.title || item.sectionDescription) && (
                              <>
                                <NavIntroduction
                                  title={item.title}
                                  description={item.sectionDescription}
                                />
                              </>
                            )}
                            {item.quickLinksTitle &&
                            item.quickLinksCollection &&
                            item.quickLinksCollection.items.length > 0 ? (
                              <>
                                <NavQuickLinks
                                  title={item.quickLinksTitle}
                                  links={createLinks({
                                    linkItems: item.quickLinksCollection,
                                  })}
                                />
                              </>
                            ) : null}
                          </GridItem>
                        ) : null}
                        {item.navigationElementsCollection &&
                        item.navigationElementsCollection.items &&
                        item.navigationElementsCollection.items.length > 0 ? (
                          <GridItem
                            borderLeft="1px solid rgba(255, 255, 255, 0.24)"
                            paddingInlineStart={6}
                          >
                            <NavAccordion
                              key={openPopoverId}
                              links={createLinks({
                                linkItems: item.navigationElementsCollection,
                              })}
                            />
                          </GridItem>
                        ) : null}
                        {(item.featuredStoryCollection &&
                          item.featuredStoryCollection.items &&
                          item.featuredStoryCollection.items.length > 0) ||
                        item.ctaBannerDefault ||
                        item.ctaBannerLoggedIn ? (
                          <GridItem
                            borderLeft="1px solid rgba(255, 255, 255, 0.24)"
                            paddingInlineStart={6}
                            display="flex"
                            flexDir="column"
                            gap={6}
                          >
                            {item.featuredStoryCollection &&
                            item.featuredStoryCollection.items &&
                            item.featuredStoryCollection.items.length > 0 ? (
                              <>
                                <NavFeaturedStories stories={item.featuredStoryCollection.items} />
                              </>
                            ) : null}
                            {isAuthenticated && item.ctaBannerLoggedIn ? (
                              <NavCtaBanner {...item.ctaBannerLoggedIn} />
                            ) : item.ctaBannerDefault ? (
                              <NavCtaBanner {...item.ctaBannerDefault} />
                            ) : null}
                          </GridItem>
                        ) : null}
                      </Grid>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </Box>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default DesktopNav
